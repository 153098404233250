import React from 'react';
import { Col, Form, Input, Row, Select } from "antd";
import { LIST_BANKS } from '@/utils/bank';

const BankInfoForm = () => {
  return (
    <Row gutter={[8, 0]}>
      <Col span={12}>
        <Form.Item
          label="Tên ngân hàng"
          name="bankName"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn tên ngân hàng",
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            placeholder="Chọn tên ngân hàng"
            filterOption={(input, option) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
            options={(LIST_BANKS || []).map((bank) => ({
              value: bank.shortName,
              label: bank.shortName,
            }))}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Tên tài khoản"
          name="accountName"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập tên tài khoản",
            },
          ]}
        >
          <Input placeholder="Nhập tên tài khoản"/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Số tài khoản"
          name="numberAccount"
          rules={[
            { required: true, message: "Vui lòng nhập số tài khoản" },
          ]}
        >
          <Input
            placeholder="Nhập số tài khoản"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Nội dung chuyển khoản"
          name="bankNote"
        >
          <Input
            placeholder="Nhập ghi chú"
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default BankInfoForm;