import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getCloseRequestTicket } from "@/services/base";
import { StatusResponse } from "@/utils/const";
import { Flex, message, Tag, Tooltip } from "antd";
import DataTable from "@/common/DataTable";
import { infoUser } from "@/recoil/atoms";
import FormSearch from "@/common/FormSearch";
import ExportExpense from "@/common/ExportExpense";
import ExpenseLink from "@/common/ExpenseLink";
import CountData from "@/common/CountData";
import { formatCurrencyVND } from "@/utils/common";
import { QuestionCircleOutlined } from "@ant-design/icons";
import moment from "moment/moment";

const CloseRequest = () => {
  document.title = "Quản lý yêu cầu kết toán";
  const [closeReqTicket, setCloseReqTicket] = useState([]);
  const [loading, setLoading] = useState(false);
  const infoUserState = useRecoilValue(infoUser);
  const [params, setParams] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  const [totalPages, setTotalPages] = useState(0);

  const columns = [
    {
      key: "id",
      title: "Mã KT",
      dataIndex: "id",
      fixed: 'left',
      align: 'center',
      render: (id, record) =>
        record.isRequestClose ? (
          "--"
        ) : (
          <ExpenseLink
            id={id}
            page={record.expenseType.shortName}
          >{`${record.expenseType.shortName}${id}`}</ExpenseLink>
        ),
    },
    {
      key: "refId",
      title: "Mã NS",
      dataIndex: "refId",
      align: 'center',
      render: (redId, record) => (
        <ExpenseLink
          id={redId || record.id}
          page="NS"
        >{`NS${redId || record.id}`}</ExpenseLink>
      ),
    },
    {
      title: "Loại hình",
      dataIndex: "purpose",
      width: 120,
      key: "purpose",
      render: (value) => (value?.purposeName ? value?.purposeName : "--"),
    },
    {
      title: <span>Số tiền <Tooltip title="Số tiền refund NS"><QuestionCircleOutlined /></Tooltip></span>,
      dataIndex: "totalAmount",
      width: 130,
      key: "totalAmount",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng ngân sách",
      dataIndex: "calculateAmount",
      width: 130,
      key: "calculateAmount",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Nhóm HD",
      dataIndex: "group",
      key: "group",
      render: (value) => (value ? value.groupName : "--"),
    },
    {
      title: "QUY TRÌNH",
      children: [
        {
          title: "Tên quy trình duyệt",
          dataIndex: "processType",
          key: "processType",
          render: (value) => (value?.name ? value?.name : "--"),
        },
        {
          title: "Bước xử lý",
          dataIndex: "processStep",
          key: "processStep",
          render: (value, rec) => {
            const isProcessingCurrentUser =
              infoUserState?.departmentId == value?.departmentId;
            const stepName = value?.stepName ? value?.stepName : "--";
            const allowStatus = ["Inprogress", "Pending"].includes(rec?.processStatus?.statusCode)
            return isProcessingCurrentUser && allowStatus && value?.stepName ? (
              <Tag color="blue">{stepName}</Tag>
            ) : (
              stepName
            );
          },
        },
      ],
    },
    {
      title: "Số tài khoản",
      dataIndex: "",
      key: "bankAccount",
      align: 'center',
      render: (value) =>
        value?.numberAccount
          ? `${value.numberAccount} - ${value.bankName}`
          : "--",
    },
    {
      title: "Trạng thái",
      dataIndex: "processStatus",
      key: "processStatus",
      align: 'center',
      render: ({ statusCode, statusName }, { processingUser }) => {
        const fullNameProcessingUser = processingUser?.fullName;
        const textProcessingUser = statusCode == "Inprogress" && fullNameProcessingUser ? `${fullNameProcessingUser} - ` : "";
        
        const colorMap = {
          Done: "green",
          Inprogress: "blue",
          Reject: "red",
          Pending: "yellow",
          default: "default"
        };

        const color = colorMap[statusCode] || colorMap.default;

        return <Tag className="m-0" color={color}>{textProcessingUser}{statusName}</Tag>;
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 120,
      align: 'center',
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
    {
      title: "Ngày chỉnh sửa",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      align: 'center',
      width: 120,
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
  ];

  const fetchCloseRequestTicket = async (value) => {
    setLoading(true);
    try {
      const res = await getCloseRequestTicket(value);
      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }
      setTotalPages(res?.pagination?.total);
      const dataWithKey = res?.data
        ? res?.data.map((i) => {
            return { ...i, key: i.id };
          })
        : [];
      setCloseReqTicket(dataWithKey);
    } catch (error) {
      console.log("Error Fetching Close Request Ticket", error);
      message.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCloseRequestTicket(params);
  }, [params]);

  const handleOnchangeSearch = useCallback(
    (searchFields) => {
      const newParams = { ...params, ...searchFields };
      setParams(newParams);
    },
    [params]
  );

  const handleOnchangePage = (pageIndex, pageSize) => {
    setParams((prev) => ({ ...prev, pageIndex, pageSize }));
  };

  return (
    <>
      <section className="flex items-end justify-between mb-4">
        <FormSearch
          setSearchParam={setParams}
          onchangeSearch={handleOnchangeSearch}
          storageKey="closeRequestFilter"
        />
      </section>
      <Flex justify="space-between">
        <CountData loading={loading} total={totalPages} text="kết toán" />
        <ExportExpense expenseCode="RequestClose" params={params}/>
      </Flex>
      <DataTable
        className="mt-2"
        columns={columns}
        dataSource={closeReqTicket}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        scroll={{ x: "max-content", y: 560 }}
        totalPage={totalPages}
        loading={loading}
        isBorder={true}
        onPageChange={handleOnchangePage}
      />
    </>
  );
};

export default CloseRequest;
