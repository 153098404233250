import {
  getAllProcessType,
  getGroupSummary,
  getListGroup,
  getListProcessingStep,
  getPagingAreas,
  getTeamList
} from "@/services/base";
import { StatusResponse } from "@/utils/const";
import { message } from "antd";
import qs from "query-string";

export const getListAreas = async (params, setListAreas, setPaginationAreas) => {
  try {
    const res = await getPagingAreas(qs.stringify(params));
    if (res.code !== StatusResponse.SUCCESS) {
      message.error(res.message);
      return;
    }

    const data = res.data?.map((item) => ({ ...item, key: item.id })) || [];
    setListAreas(data);

    if (setPaginationAreas) {
      setPaginationAreas((prev) => ({ ...prev, total: res.pagination?.total }));
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getListTeams = async (value, setListTeams, setPaginationTeams) => {
  try {
    const res = await getTeamList(qs.stringify(value));

    if (res.code !== 200 && res.error !== 0) {
      message.error(res?.message);
      return;
    }

    const data = res.data?.map((item) => ({ ...item, key: item.id })) || [];
    setListTeams(data);

    if (setPaginationTeams) {
      setPaginationTeams((prev) => ({ ...prev, total: res.pagination?.total }));
    }
  } catch (err) {
    console.error("Error in getListDepartment:", err);
  }
};

export const getListProcessTypes = async (body, setListProcessType) => {
  const res = await getAllProcessType(qs.stringify(body));
  if (res.code !== StatusResponse.SUCCESS) {
    setListProcessType([]);
    return;
  }
  setListProcessType(res?.data || []);
};

export const getSteps = async (processTypeId, setSteps) => {
  try {
    const params = {
      processTypeId,
    };
    const paramString = qs.stringify(params);

    const res = await getListProcessingStep(paramString);
    if (res.code !== 200 && res.error !== 0) return message.error(res?.message);
    setSteps(res?.data);
  } catch (err) {
    console.log(err);
  }
};

export const fetchGroupSummary = async (groupId, setGroupSummary) => {
  try {
    if (groupId) {
      const res = await getGroupSummary(groupId);
      if (res.code !== 200 && res.error !== 0) return message.error(res?.message);
      setGroupSummary(res?.data);
    } else {
      setGroupSummary(null);
    }
  } catch (err) {
    console.log(err);
  }
};

export const getListGroups = async (params, setListGroup, setPaginationGroup) => {
  try {
    const res = await getListGroup(params);
    if (res.code !== StatusResponse.SUCCESS) {
      message.error(res.message);
      return;
    }

    const data = res.data?.map((item) => ({ ...item, key: item.id })) || [];
    setListGroup(data);

    if (setPaginationGroup) {
      setPaginationGroup((prev) => ({ ...prev, total: res.pagination?.total }));
    }
  } catch (error) {
    console.log("error", error);
  }
};
