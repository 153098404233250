import * as url from "@/configs/url_api";
import { APIBase } from "@/configs/axiosBase";

const apiBase = new APIBase();

//Auth
export const authSignIn = (data) => apiBase.post(url.API_AUTH_LOGIN, data);
export const authRefreshToken = (data) =>
  apiBase.post(url.API_AUTH_REFRESH_TOKEN, data);
export const changePass = (data) => apiBase.post(url.API_CHANGE_PASS, data);

// Dashboard
export const getSumaryTicketStatus = (data) =>
  apiBase.get(`${url.API_GET_SUMARY_TICKET_STAUS}${data ? `?${data}` : ""}`);
export const getSumaryTicketYear = (data) =>
  apiBase.get(`${url.API_GET_SUMARY_TICKET_YEAR}${data ? `?${data}` : ""}`);
export const getSumaryTicketMonth = (data) =>
  apiBase.get(`${url.API_GET_SUMARY_TICKET_MONTH}${data ? `?${data}` : ""}`);

//Users
export const getUserById = (id) => apiBase.get(`${url.API_USER}/${id}`);
export const getPagingUsers = (data) =>
  apiBase.get(`${url.API_GET_PAGING_USERS}${data ? `?${data}` : ""}`);
export const createUser = (data) => apiBase.post(url.API_CREATE_USER, data);
export const updateUser = (data) => apiBase.put(url.API_UPDATE_USER, data);
export const deleteUser = (id) => apiBase.delete(`${url.API_USER}/${id}`);
export const updateProfile = (data) =>
  apiBase.put(url.API_UPDATE_PROFILE, data);
export const importFileUser = (data) =>
  apiBase.post(
    `${url.API_IMPORT_USER}`,
    { file: data?.file },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
export const exportFileUser = (data) => apiBase.post(url.API_EXPORT_USER, data);

//Sites
export const getAllSites = () => apiBase.get(url.API_SITES);
export const getPagingSites = (data) =>
  apiBase.get(`${url.API_GET_PAGING_SITES}${data ? `?${data}` : ""}`);
export const getListSites = () => apiBase.get(url.API_GET_LIST_SITES);
export const createSite = (data) => apiBase.post(url.API_SITES, data);
export const updateSite = (id, data) => apiBase.put(url.API_SITES, id, data);
export const deleteSite = (id) => apiBase.delete(`${url.API_SITES}/${id}`);

// expense-request
export const getListExpenseApi = (data) =>
  apiBase.get(url.API_GET_LIST, { params: data });
export const updateExpenseApi = (data) =>
  apiBase.put(url.API_UPDATE_EXPENSE, data);
export const createExpenseApi = (data) =>
  apiBase.post(url.API_CREATE_EXPENSE, data);
export const deleteExpenseApi = (id) =>
  apiBase.delete(`${url.API_DELETE_EXPENSE}/${id}`);
export const getDetailExpenseApi = (id, expenseCode) =>
  apiBase.get(`${url.API_GET_DETAIL_EXPENSE}/${id}?expenseRequestType=${expenseCode}`);
export const handleApproveExprense = (data) =>
  apiBase.put(`${url.API_EXPENSE_APPROVE}`, data);
export const handleRejectExpense = (body) =>
  apiBase.put(`${url.API_EXPENSE_REJECT}`, body);
export const handlePendingExpense = (body) =>
  apiBase.put(`${url.API_EXPENSE_PENDING}`, body);
export const handleCloseExpense = (id, processTypeId) =>
  apiBase.put(`${url.API_EXPENSE_CLOSE}/${id}?processTypeId=${processTypeId}`);
export const handleActiveExpense = (id) =>
  apiBase.put(`${url.API_EXPENSE_ACTIVE}/${id}`);
export const handleNextExpense = (id) =>
  apiBase.put(`${url.API_EXPENSE_NEXT}/${id}`);
export const handleActionExpense = (key, id) =>
  apiBase.put(`${url.API_EXPENSE_GENERATE}/${key}/${id}`);
export const getCloseRequestTicket = (data) =>
  apiBase.get(url.API_REQUEST_CLOSE, { params: data });
export const processingUserExpense = (expenseId) =>
  apiBase.put(`${url.API_EXPENSE_PROCESSING_USER}/${expenseId}`);
export const rollbackDailyReport = (data) =>
  apiBase.put(url.API_ROLLBACK_DAILY_REPORT, data);

export const getListExpenseChildAdj = (data) =>
  apiBase.get(`${url.API_GET_EXPENSE_DETAIL_CHILD}`, { params: data });

export const getListExpenseType = (data) =>
  apiBase.get(`${url.API_EXPENSE_TYPE}`, { params: data });
export const updateSupervisor = (data) =>
  apiBase.put(`${url.API_UPDATE_SUPERVISOR}${data ? `?${data}` : ""}`);
export const exportExpense = (expenseCode, body) => 
  apiBase.post(`${url.API_EXPENSE_EXPORT_FILE}?expenseTypeCode=${expenseCode}`, body);
export const exportAllExpense = (body) => 
  apiBase.post(`${url.API_EXPENSE_EXPORT_ALL_FILE}`, body);

// Proposed category
export const getProposedCategoryList = async ({
  textSearch,
  pageIndex,
  pageSize,
}) => {
  const queryParams = new URLSearchParams();
  if (textSearch) queryParams.set("textSearch", textSearch);
  if (pageIndex) queryParams.set("pageIndex", pageIndex);
  if (pageSize) queryParams.set("pageSize", pageSize);

  const queryString = queryParams.toString();
  const endpoint = `${url.GET_PROPOSED_CATEGORY_LIST}${
    queryString ? `?${queryString}` : ""
  }`;

  return await apiBase.get(endpoint);
};
export const createProposedCategory = (data) =>
  apiBase.post(url.ADD_PROPOSED_CATEGORY, data);
export const updateProposedCategory = (data) =>
  apiBase.put(url.UPDATE_PROPOSED_CATEGORY, data);
export const deleteProposedCategory = (id) =>
  apiBase.delete(`${url.DELETE_PROPOSED_CATEGORY}/${id}`);

//Roles
export const getPagingRole = (data) =>
  apiBase.get(`${url.API_GET_PAGING_ROLES}${data ? `?${data}` : ""}`);

// Departments
export const getPagingDepartments = (data) =>
  apiBase.get(`${url.API_GET_PAGING_DEPARTMENTS}${data ? `?${data}` : ""}`);
export const getListDepartmentTypes = (data) =>
  apiBase.get(`${url.API_GET_LIST_DEPARTMENT_TYPE}${data ? `?${data}` : ""}`);
export const createDepartments = (data) =>
  apiBase.post(url.API_ADD_DEPARTMENT, data);
export const updateDepartments = (id, data) =>
  apiBase.put(url.API_UPDATE_DEPARTMENT, id, data);
export const deleteDepartments = (id) =>
  apiBase.delete(`${url.API_DEPARTMENT}/${id}`);

// Area
export const getPagingAreas = (data) =>
  apiBase.get(`${url.API_GET_LIST_AREA}${data ? `?${data}` : ""}`);
export const getAreaDetail = (id) => apiBase.get(`${url.API_AREA}/${id}`);
export const createArea = (data) => apiBase.post(url.API_ADD_AREA, data);
export const updateArea = (data) => apiBase.put(url.API_UPDATE_AREA, data);
export const deleteArea = (id) => apiBase.delete(`${url.API_AREA}/${id}`);

// ProcessingStep
export const getPagingProcessingStep = (data) =>
  apiBase.get(`${url.API_GET_PAGING_PROCESSINGSTEPS}${data ? `?${data}` : ""}`);
export const getListProcessingStep = (data) =>
  apiBase.get(`${url.API_GET_LIST_PROCESSINGSTEPS}${data ? `?${data}` : ""}`);
export const createProcessingStep = (data) =>
  apiBase.post(url.API_CREATE_PROCESSINGSTEPS, data);
export const updateProcessingStep = (id, data) =>
  apiBase.put(url.API_UPDATE_PROCESSINGSTEPS, id, data);
export const deleteProcessingStep = (id) =>
  apiBase.delete(`${url.API_PROCESSINGSTEPS}/${id}`);
// GET INIT PROCESS- PROCESSINGSTEP
export const getInitProcessingStep = (processTypeId) =>
  apiBase.get(
    `${url.API_PROCESSINGSTEPS_GET_INIT_PROCESS}?processTypeId=${processTypeId}`
  );

// GET SUPERVISOR BY PROCESSID (LẤY DANH SÁCH NGƯỜI PHỤ TRÁCH THEO QUY TRÌNH CÓ PAGING)
export const getSupervisorByProcessIdPaging = (data) =>
  apiBase.get(`${url.API_PROCESSINGSTEPS_GETSUPERVISOR_BY_PROCESSID}`, {
    params: data,
  });
export const getProcessingStepSupervisor = (data) =>
  apiBase.get(`${url.API_PROCESSINGSTEPS_SUPERVISOR}${data ? `?${data}` : ""}`);

// ProcessType
export const getAllProcessType = (data) =>
  apiBase.get(`${url.API_GET_PROCESS_TYPE}${data ? `?${data}` : ""}`);
export const getListProcessTypeById = (id) =>
  apiBase.get(`${url.API_GET_PROCESS_TYPE}?expenseTypeId=${id}`);
export const addProcessType = (data) =>
  apiBase.post(url.API_ADD_PROCESS_TYPE, data);
export const updateProcessType = (data) =>
  apiBase.put(url.API_UPDATE_PROCESS_TYPE, data);
export const deleteProcessType = (id) =>
  apiBase.delete(`${url.API_DELETE_PROCESS_TYPE}?processTypeId=${id}`);
export const copyProcessType = (data) => 
  apiBase.post(url.API_COPY_PROCESS_TYPE, data);

//processStatus
export const getListProcessStatusApi = (data) =>
  apiBase.get(url.API_GET_PROCESS_STATUS, { params: data });

// Purpose
export const getListPurpose = (data) =>
  apiBase.get(url.API_GET_PURPOSE, { params: data });

//ExpenseDetail
export const createExpenseDetail = (data) =>
  apiBase.post(url.API_CREATE_EXPENSE_DETAIL, data);
export const updateExpenseDetail = (data) =>
  apiBase.put(url.API_UPDATE_EXPENSE_DETAIL, data);
export const deleteExpenseDetail = (id) =>
  apiBase.delete(`${url.API_DELETE_EXPENSE_DETAIL}?expenseDetailId=${id}`);

// MessInfor
export const getMessInfoList = (id) =>
  apiBase.get(`${url.API_GET_MESS_INFO}?expenseId=${id}`);
export const createMessInfor = (data) =>
  apiBase.post(url.API_CREATE_UPDATE_MESS_INFO, data);
export const updateMessInfor = (data) =>
  apiBase.put(url.API_CREATE_UPDATE_MESS_INFO, data);
export const deleteMessInfor = (id) =>
  apiBase.delete(`${url.API_DELETE_MESS_INFO}?expenseId=${id}`);

// Team
export const getTeamList = (data) =>
  apiBase.get(`${url.API_TEAM}${data ? `?${data}` : ""}`);
export const addTeam = (data) => apiBase.post(url.API_TEAM, data);
export const updateTeam = (data) => apiBase.put(url.API_TEAM, data);
export const deleteTeam = (id) => apiBase.delete(`${url.API_TEAM}/${id}`);

// Logaction
export const getPagingLogaction = (data) =>
  apiBase.get(url.API_GET_LOG, { params: data });

// Upload File
export const postFile = (data) =>
  apiBase.post(
    `${url.API_UPLOAD_FILE}?fileType=${
      data.fileType === undefined ? "" : data?.fileType
    }`,
    { file: data?.file },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

// Notification Sigalr
export const getNotification = (data) =>
  apiBase.get(url.API_NOTIFICATION, { params: data });

export const viewNotification = (data) =>
  apiBase.put(url.API_VIEW_NOTIFICATION, data);

export const deleteNotification = (data) =>
  apiBase.put(url.API_DELETE_NOTIFICATION, data);
// Service worker notification
export const subscribeNotification = (data) =>
  apiBase.post(url.API_SUBSCRIBE, data);
export const deleteSubscriptionNotification = (data) =>
  apiBase.post(url.API_DELETE_SUBSCRIPTION, data);

// PurchaseRequest
export const getDailyReport = (data) =>
  apiBase.get(url.GET_DAILY_REPORT, { params: data });
export const createUpdatePurchaseRequest = (data) =>
  apiBase.post(url.ADD_EDIT_PURCHASE_REQUEST, data);
export const deleteFile = (data) =>
  apiBase.delete(url.API_DELETE_FILE, { params: data });

// Decrease Request
export const getListDecreaseRequestAdj = (data) =>
  apiBase.get(`${url.API_DECREASE_REQUEST}`, { params: data });

// Increase Request
export const getListIncreaseRequestAdj = (data) =>
  apiBase.get(`${url.API_INCREASE_REQUEST}`, { params: data });

// Figure
export const getFigureList = (data) =>
  apiBase.get(`${url.API_GET_FIGURE}`, { params: data });
export const getFigureById = (id) => apiBase.get(`${url.API_FIGURE}/${id}`);

// GROUP SUMMARY
export const getGroupSummary = (id) =>
  apiBase.get(`${url.API_GROUP_SUMMARY}/${id}`);

export const importFileFigure = (data) =>
  apiBase.post(
    `${url.API_IMPORT_FILE}`,
    { file: data?.file },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const exportFileFigure = () => apiBase.post(`${url.API_EXPORT_FILE}`);

// Target Config
export const getTargetConfigList = (data) =>
  apiBase.get(`${url.API_GET_TARGET_CONFIG}`, { params: data });

export const updateTargetConfig = (data) =>
  apiBase.put(url.API_UPDATE_TARGET_CONFIG, data);

// Group
export const getListGroup = (data) =>
  apiBase.get(url.API_GET_GROUP, { params: data });

export const createGroup = (data) => apiBase.post(url.API_GET_GROUP, data);
export const updateGroup = (data) => apiBase.put(url.API_GET_GROUP, data);

export const deleteGroup = (id) => apiBase.delete(`${url.API_GET_GROUP}/${id}`);

export const exportFileGroup = (data) =>
  apiBase.post(url.API_GROUP_EXPORT_FILE, data);

export const importFileGroup = (data) =>
  apiBase.post(
    `${url.API_GROUP_IMPORT_FILE}`,
    { file: data?.file },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

// Partner
export const getListPartner = (data) => apiBase.get(url.API_GET_PARTNER_LIST, { params: data });
export const createPartner = (data) => apiBase.post(url.API_ADD_PARTNER, data);
export const updatePartner = (data) => apiBase.put(url.API_UPDATE_PARTNER, data);
export const deletePartner = (id) => apiBase.delete(`${url.API_PARTNER}/${id}`);
