import ButtonPrimary from "@/common/ButtonPrimary";
import DataTable from "@/common/DataTable";
import { deleteDepartments, getPagingDepartments } from "@/services/base";
import { StatusResponse } from "@/utils/const";
import { Divider, Form, Input, message, Modal, Popconfirm, Typography, } from "antd";
import { ExclamationCircleFilled, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import DepartmentsFormAction from "@/components/departments/DepartmentsFormAction";
import moment from "moment";
import CountData from "@/common/CountData";
import CompForm from "@/common/CompForm";
import debounce from "debounce";
import GroupActionButton from "@/common/GroupActionButton";
import qs from "query-string";
import { useRecoilValue } from "recoil";
import { allDepartType } from "@/recoil/atoms";

const { confirm } = Modal;
const initStateModal = {
  isOpenModal: false,
  isCreate: false,
  titleModal: "",
  buttonOkModal: "",
  editData: {},
};

const initParams = {
  textSearch: "",
  pageIndex: 1,
  pageSize: 20,
};

const DepartmentPage = () => {
  document.title = "Quản lý bộ phận";
  const [departments, setDepartments] = useState([]);
  const [selectedDeparments, setSelectedDeparments] = useState([]);
  const [stateModal, setStateModal] = useState(initStateModal);
  const [loading, setLoading] = useState(false);
  const [formSearch] = Form.useForm();
  const [params, setParams] = useState(initParams);
  const [paginationDepartment, setPaginationDepartment] = useState({
    pageIndex: 1,
    pageSize: 20,
    total: 0,
    pages: 1,
  });

  const getListDepartment = async (params) => {
    try {
      const res = await getPagingDepartments(qs.stringify(params));

      if (res.code !== 200 && res.error !== 0) {
        message.error(res?.message);
        return;
      }

      const data = res.data?.map((item) => ({ ...item, key: item.id })) || [];
      setDepartments(data);

      setPaginationDepartment((prev) => ({
        ...prev,
        total: res.pagination?.total
      }));
    } catch (err) {
      console.error("Error in getListDepartment:", err);
    }
  };

  useEffect(() => {
    getListDepartment(params);
  }, [params]);

  const updateTeams = (data) => {
    setStateModal({
      isOpenModal: true,
      isCreate: false,
      titleModal: "Chỉnh sửa bộ phận",
      buttonOkModal: "Chỉnh sửa",
      editData: data,
    });
  };

  const onDeleteDepartment = async (id) => {
    setLoading(true)
    try {
      const res = await deleteDepartments(id);
      if (res.code === StatusResponse.SUCCESS) {
        message.success("Xóa bộ phận thành công");
        reloadDepart();
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  const showModalConfirmDelete = (record) => {
    confirm({
      title: "Bạn có chắc chắn muốn xóa?",
      icon: <ExclamationCircleFilled/>,
      content: "Khi xóa sẽ không thể hoàn tác!",
      okText: "Xóa",
      okType: "primary",

      cancelText: "Hủy",
      onOk() {
        onDeleteDepartment(record);
      },
      onCancel() {},
      cancelButtonProps: {
        type: 'primary',
        danger: true
      }
    });
  };

  const columns = [
    {
      key: "departmentCode",
      title: "Mã bộ phận",
      dataIndex: "departmentCode",
    },
    {
      key: "departmentName",
      title: "Tên bộ phận",
      dataIndex: "departmentName",
    },
    {
      key: "departmentType",
      title: "Loại bộ phận",
      dataIndex: "departmentType",
      render: (rc) => (rc?.departmentTypeName)
    },
    {
      key: "creationDate",
      title: "Thời gian tạo",
      dataIndex: "creationDate",
      render: (creationDate) => {
        return (
          <Typography.Text>
            {moment(creationDate + "z").format("DD/MM/YYYY HH:mm")}
          </Typography.Text>
        );
      },
    },
    {
      title: "Hành động",
      key: "action",
      render: (_, record) => (
        <GroupActionButton record={record} showModalDelete={showModalConfirmDelete} updateFn={updateTeams} />
      ),
    },
  ];

  const handleOnchangePage = (pageIndex, pageSize) => {
    setParams((prev) => ({ ...prev, pageIndex, pageSize }));
  };

  const createDepartment = () => {
    setStateModal({
      isOpenModal: true,
      isCreate: true,
      titleModal: "Thêm mới bộ phận",
      buttonOkModal: "Thêm",
      editData: null,
    });
  };

  const closeModal = () => {
    setStateModal(initStateModal);
  };

  const reloadDepart = () => {
    getListDepartment(initParams);
    setParams(initParams);
    setSelectedDeparments([]);
  };

  const deleteMultipleDepartments = async () => {
    setLoading(true);
    try {
      const results = await Promise.all(
        selectedDeparments.map(dep => deleteDepartments(dep).catch(err => {
          console.error(`Failed to delete department ${dep}:`, err);
          return { isError: true, message: err.message };
        }))
      );

      const deleteFails = results.filter(res => res.isError).map(res => res.message);

      if (deleteFails.length > 0) {
        deleteFails.forEach(item => message.error(item));
      } else {
        message.success("Thành công");
      }

      reloadDepart();
    } catch (err) {
      console.error("Error in deleteMultipleDepartments:", err);
    } finally {
      setLoading(false);
    }
  };

  const handlePopconfirm = {
    confirm: deleteMultipleDepartments,
    cancle: () => {},
  };

  const onSearch = useMemo(() =>
      debounce((data) => {
        getListDepartment(data);
      }, 300),
    []);

  const handleOnchangeSearch = (event) => {
    const value = event.target.value.trim().replace(/\s+/g, " ");
    onSearch({ ...params, textSearch: value });
  };

  return (
    <>
      <section className="flex items-end justify-between mb-4">
        <CompForm
          className={"flex gap-3 items-center"}
          children={
            <Form.Item name="textSearch" className="w-[300px] !mt-0">
              <Input
                placeholder="Tìm kiếm bộ phận..."
                allowClear
                onChange={(e) => handleOnchangeSearch(e)}
                addonBefore={<SearchOutlined/>}
              />
            </Form.Item>
          }
          activeBtnSub={false}
          form={formSearch}
        />
        <div className="flex gap-3">
          {selectedDeparments.length > 0 && (
            <Popconfirm
              title="Xóa bộ phận"
              placement="topLeft"
              description="Bạn có thật sự muốn xóa những bộ phận đã chọn, điều này không thể hoàn tác?"
              onConfirm={handlePopconfirm.confirm}
              onCancel={handlePopconfirm.cancle}
              okText="Xác nhận"
              cancelText="Hủy"
              cancelButtonProps={{
                type: 'primary',
                danger: true
              }}
            >
              <Typography.Text
                className="bg-red-500 hover:bg-red-600 text-white flex items-center px-2 rounded-lg cursor-pointer"
                size="middle"
                style={{ border: "none" }}
              >
                Xóa {selectedDeparments.length} bộ phận đã chọn
              </Typography.Text>
            </Popconfirm>
          )}
          <ButtonPrimary text="Thêm bộ phận" onClick={createDepartment}/>
        </div>
      </section>
      <CountData loading={loading} total={paginationDepartment.total} text="bộ phận"/>
      <Divider/>
      <DataTable
        columns={columns}
        dataSource={departments}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={paginationDepartment.total}
        loading={loading}
        selectedRowKeys={selectedDeparments}
        setSelectedRowKeys={setSelectedDeparments}
        onPageChange={handleOnchangePage}
        scroll={{ y: 500 }}
      />
      <DepartmentsFormAction
        stateModal={stateModal}
        closeModal={closeModal}
        reloadDepart={reloadDepart}
      />
    </>
  );
};

export default DepartmentPage;
