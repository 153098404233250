import React, { useEffect, useMemo, useState } from "react";
import { Divider, Form, Input, message, Modal, Popconfirm, Typography } from "antd";
import { ExclamationCircleFilled, SearchOutlined } from "@ant-design/icons";
import debounce from "debounce";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import ButtonPrimary from "@/common/ButtonPrimary";
import DataTable from "@/common/DataTable";
import CountData from "@/common/CountData";
import GroupActionButton from "@/common/GroupActionButton";
import CompForm from "@/common/CompForm";
import usePartnerHook from "@/hooks/usePartnerHook";
import PartnersFormAction from "@/page/Partners/PartnersFormAction";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import { DEPARTMENT__CODE, ROLES } from "@/utils/const";

const initStateModal = {
  isOpenModal: false,
  isCreate: false,
  titleModal: "",
  buttonOkModal: "",
  editData: {},
};

const { confirm } = Modal;

const PartnersPage = () => {
  document.title = "Quản lý đối tác";

  const { department, role } = useRecoilValue(infoUser) || {};
  const { partners, getPartnersPage, addPartner, modifyPartner, removePartner, loading, pagination } = usePartnerHook();
  const [searchParams, setSearchParams] = useSearchParams();
  const [formSearch] = Form.useForm();
  const [stateModal, setStateModal] = useState(initStateModal);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [params, setParams] = useState({
    textSearch: searchParams.get("textSearch") || "",
    pageIndex: parseInt(searchParams.get("pageIndex")) || 1,
    pageSize: parseInt(searchParams.get("pageSize")) || 20,
  });

  const allowedAction = [ROLES.ADMIN].includes(role?.roleCode) || [DEPARTMENT__CODE.TLQC].includes(department?.departmentCode);

  useEffect(() => {
    setSearchParams({
      textSearch: params.textSearch,
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
    });
    fetchDataPartners(params);
  }, [params, setSearchParams]);

  const fetchDataPartners = async (value) => {
    try {
      await getPartnersPage(value);
    } catch (err) {
      message.error("Failed to fetch partners.");
    }
  };

  const onSearch = (data) => {
    setParams({ ...params, pageIndex: 1, ...data });
  };

  const debouncedSearch = useMemo(() => debounce((data) => onSearch(data), 300), [params]);

  const handleOnchangeSearch = (event) => {
    const value = event.target.value.trim().replace(/\s\s+/g, " ");
    debouncedSearch({ textSearch: value });
  };

  const handleOnchangePage = (pageIndex, pageSize) => {
    setParams((prev) => ({ ...prev, pageIndex, pageSize }));
  };

  const createPartner = () => {
    setStateModal({
      isOpenModal: true,
      isCreate: true,
      titleModal: "Thêm mới đối tác",
      buttonOkModal: "Thêm",
      editData: null,
    });
  };

  const updatePartner = (data) => {
    setStateModal({
      isOpenModal: true,
      isCreate: false,
      titleModal: "Chỉnh sửa đối tác",
      buttonOkModal: "Chỉnh sửa",
      editData: data,
    });
  };

  const closeModal = () => {
    setStateModal(initStateModal);
  };

  const actionPartners = async (data, type) => {
    if (type === "create") {
      return await addPartner(data);
    } else if (type === "update") {
      return await modifyPartner({ ...data, id: stateModal.editData.id });
    }
  };

  const showModalConfirmDelete = (record) => {
    confirm({
      title: "Bạn có chắc chắn muốn xóa?",
      icon: <ExclamationCircleFilled />,
      content: "Khi xóa sẽ không thể hoàn tác!",
      okText: "Xóa",
      okType: "danger",
      cancelText: "Hủy",
      onOk() {
        onDeletePartner(record);
      },
    });
  };

  const onDeletePartner = async (id) => {
    const { success, message: resultMessage } = await removePartner(id);

    if (success) {
      message.success(resultMessage);
      refreshPage();
    } else {
      message.error(resultMessage);
    }
  };

  const columns = [
    {
      key: "partnerCode",
      title: "Mã đối tác",
      dataIndex: "partnerCode",
    },
    {
      title: "Tài khoản đối tác",
      dataIndex: "partnerAccount",
      key: "partnerAccount",
    },
    {
      key: "partnerFullName",
      title: "Họ tên đối tác",
      dataIndex: "partnerFullName",
    },
    {
      title: "Zalo",
      dataIndex: "phoneZalo",
      key: "phoneZalo",
      align: 'center',
    },
    {
      title: "Telegram",
      dataIndex: "phoneTele",
      key: "phoneTele",
      align: 'center',
    },
    {
      title: "Thời gian tạo",
      dataIndex: "creationDate",
      key: "creationDate",
      align: 'center',
      render: (creationDate) => (
        <Typography.Text>
          {moment(creationDate).format("DD/MM/YYYY HH:mm")}
        </Typography.Text>
      ),
    },
    ...(allowedAction
      ? [{
        title: "Hành động",
        key: "action",
        align: 'center',
        render: (_, record) => (
          <GroupActionButton
            record={record}
            showModalDelete={showModalConfirmDelete}
            updateFn={() => updatePartner(record)}
          />
        ),
      }]
      : []),
  ];

  const refreshPage = () => {
    getPartnersPage({
      textSearch: params.textSearch,
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize
    })
  }

  return (
    <>
      <section className="flex items-end justify-between mb-4">
        <CompForm
          className="flex gap-3 items-center"
          children={
            <Form.Item name="textSearch" className="w-[300px] !m-0">
              <Input
                placeholder="Tìm kiếm đối tác..."
                allowClear
                onChange={handleOnchangeSearch}
                defaultValue={params.textSearch}
                addonBefore={<SearchOutlined />}
              />
            </Form.Item>
          }
          activeBtnSub={false}
          form={formSearch}
        />
        <div className="flex gap-3">
          {selectedPartners.length > 0 && (
            <Popconfirm
              title="Xóa đối tác"
              placement="topLeft"
              description="Bạn có thật sự muốn xóa những đối tác đã chọn, điều này không thể hoàn tác?"
              onConfirm={() => selectedPartners.forEach(onDeletePartner)}
              okText="Xác nhận"
              cancelText="Hủy"
              cancelButtonProps={{
                type: 'primary',
                danger: true
              }}
            >
              <Typography.Text className="bg-red-500 hover:bg-red-600 text-white flex items-center px-2 rounded-lg cursor-pointer">
                Xóa {selectedPartners.length} đối tác đã chọn
              </Typography.Text>
            </Popconfirm>
          )}
          {allowedAction && <ButtonPrimary text="Thêm đối tác" onClick={createPartner} />}
        </div>
      </section>

      <CountData loading={loading} total={pagination.total} text="đối tác" />

      <Divider />

      <DataTable
        columns={columns}
        dataSource={partners.map((p) => ({ ...p, key: p?.id })) || []}
        currentPage={pagination.pageIndex}
        pageSize={pagination.pageSize}
        totalPage={pagination.total}
        loading={loading}
        selectedRowKeys={allowedAction ? selectedPartners : null}
        setSelectedRowKeys={allowedAction ? setSelectedPartners : null}
        onPageChange={handleOnchangePage}
      />

      <PartnersFormAction
        stateModal={stateModal}
        closeModal={closeModal}
        actionPartner={actionPartners}
        currentPage={pagination.pageIndex}
        reload={refreshPage}
      />
    </>
  );
};

export default PartnersPage;
