import TagStatus from "@/components/listRequset/TagStatus";
import { formatCurrencyVND } from "@/utils/common";
import {
  Divider,
  Input,
  Modal,
  Typography,
} from "antd";
import {
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import { getListExpenseChildAdj } from "@/services/base";
import DataTable from "@/common/DataTable";
import ExpenseLink from "@/common/ExpenseLink";
import { debounce } from "lodash";
import { goToPage } from "@/utils/utils";

const ModalExpenseDecreaseIncrease = forwardRef((props, ref) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const initalParams = {
    textSearch: "",
    expenseId: id,
    pageIndex: 1,
    pageSize: 10,
  };
  const [searchParams, setSearchParam] = useState({
    textSearch: "",
    expenseId: id,
    pageIndex: 1,
    pageSize: 10,
  });
  const [state, setState] = useState({
    open: false,
  });
  const [totalPage, setTotalPage] = useState(0);
  const [dataRequestAdj, setDataRequestAdj] = useState([]);
  const toggle = useCallback(() => {
    setState((p) => ({
      ...p,
      open: !p.open,
    }));
  }, []);

  const fetchDataListExpenseAdj = async (params) => {
    try {
      setLoading(true);
      const res = await getListExpenseChildAdj(params);
      if (res.code === 200 && res.error === 0) {
        setDataRequestAdj(res.data.map((u) => ({ ...u, key: u.id })));
        setTotalPage(res?.pagination?.total);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!state.open) return setSearchParam(initalParams);
    fetchDataListExpenseAdj(searchParams);
  }, [state.open]);

  useImperativeHandle(
    ref,
    () => {
      return {
        toggle,
      };
    },
    [toggle]
  );

  const renderStatusCol = (value) => {
    return <TagStatus record={value}/>;
  };

  const columns = [
    {
      title: "Mã phiếu",
      dataIndex: "id",
      key: "id",
      width: 85,
      render: (id, record) => (
        <ExpenseLink id={id} page={record?.expenseType?.shortName}>{`${record.expenseType.shortName}${id}`}</ExpenseLink>
      ),
    },
    {
      title: "Loại phiếu",
      dataIndex: "expenseType",
      key: "expenseType",
      render: (expenseType) => {
        return <Typography.Text>{expenseType?.name}</Typography.Text>;
      },
    },
    {
      title: "Loại hình quảng cáo",
      dataIndex: "purpose",
      key: "purpose",
      render: (value, rec) => {
        const isNVL = value?.purposeCode == "NVL" && rec?.numOfPage;

        if (isNVL) {
          return (value?.purposeName ? <>{value?.purposeName} - <span className="text-[#1677ff]">{rec?.numOfPage} page</span></> : "--")
        }
        
        return (value?.purposeName ? value?.purposeName : "--")
      },
    },
    {
      title: "Số tiền",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Ngày tạo",
      dataIndex: "creationDate",
      key: "creationDate",
      render: (value) => moment(value + "z").format("DD/MM/YYYY"),
    },
    {
      title: "Tên quy trình",
      dataIndex: "processType",
      key: "processType",
      render: (value) => (value ? value.name : "--"),
    },
    {
      title: "Bước xử lý",
      dataIndex: "processStep",
      key: "processStep",
      render: (value) => (value ? value?.stepName : "--"),
    },
    {
      title: "Trạng thái",
      dataIndex: "processStatus",
      key: "123",
      render: (_, rec) => renderStatusCol(rec),
    },
    {
      title: "Người duyệt",
      dataIndex: "userApprove",
      key: "0",
      render: (value) => {
        return <>{value?.fullName ? value?.fullName : "--"}</>;
      },
    }
  ];

  const onchangePage = (pageIndex, pageSize) => {
    setSearchParam((prevParams) => ({
      ...prevParams,
      pageIndex: pageIndex,
      pageSize: pageSize,
    }));
    fetchDataListExpenseAdj({
      pageIndex,
      pageSize,
      expenseId: searchParams.expenseId,
      textSearch: searchParams.textSearch,
    });
  };
  const onSearch = (data) => {
    setSearchParam(data);
    fetchDataListExpenseAdj(data);
  };

  const debouncedSearch = useMemo(() => {
    return debounce((data) => onSearch(data), 300);
  }, []);

  const handleOnchangeSearch = useCallback(
    (event) => {
      const value = event.target.value.trim().replace(/\s\s+/g, " ");
      debouncedSearch({ ...searchParams, textSearch: value });
    },
    [searchParams]
  );

  return (
    <Modal
      title="TẤT CẢ ĐỀ XUẤT BỔ SUNG"
      open={state.open}
      width={1600}
      onCancel={toggle}
      // maskClosable={false}
      className="ModalExpenseDecreaseIncrease top-3"
      footer={false}
    >
      <Divider/>
      <div className="max-h-[800px] overflow-y-auto overflow-x-hidden Container__ModalExpenseDecreaseIncrease">
        <div>
          <Input
            className="w-[300px]"
            allowClear
            addonBefore={<SearchOutlined/>}
            size="large"
            placeholder="Tìm kiếm...."
            onChange={handleOnchangeSearch}
          />
        </div>
        <Divider/>
        <DataTable
          columns={columns}
          dataSource={dataRequestAdj || []}
          currentPage={searchParams.pageIndex}
          pageSize={searchParams.pageSize}
          totalPage={+totalPage}
          loading={loading}
          scroll={{ x: 1400, y: 500 }}
          isBorder
          onPageChange={onchangePage}
        />
      </div>
    </Modal>
  );
});

export default ModalExpenseDecreaseIncrease;
