import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Row,
  Divider,
  message,
  Col,
  Select,
  Spin,
} from "antd";
import {
  createUpdatePurchaseRequest,
  postFile,
  getInitProcessingStep,
  getSupervisorByProcessIdPaging
} from "@/services/base";
import { PROCESSING_EXPENSE_CODE, StatusResponse } from "@/utils/const";
import "./styles.css";
import { omit } from "lodash";
import { goToPage, uploadFileAsync, autoFillAmountFields } from "@/utils/utils";
import CardFormRequest from "@/common/FormCardRequest";
import { useAudioRecorder } from "react-audio-voice-recorder";
import FormDailyReportForm from "@/components/listRequset/FormDailyReportFormRequest";
import { getListProcessTypes } from "@/utils/fetchData";
import { useNavigate } from "react-router";
import SupervisorInfoForm from "@/components/form/SupervisorInfoForm";
import AddCategoryForm from "@/components/form/AddCategoryForm";
import UploadImageForm from "@/components/form/UploadImageForm";
import UploadVoiceForm from "@/components/form/UploadVoiceForm";
import BankInfoForm from "@/components/form/BankInfoForm";
import LastReportForm from "@/components/form/LastReportForm";
import PartnerInfo from "@/components/form/PartnerInfo";

const ModalDailyReportForm = ({
  modalState,
  handleCancel,
  dataDetail
}) => {
  const { isOpen, title } = modalState
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [listImage, setListImage] = useState([]);
  const [processTypeList, setProcessTypeList] = useState([]);
  const [processTypeId, setProcessTypeId] = useState();
  const [processInit, setProcessInit] = useState(null);
  const [voice, setVoice] = useState(null);
  const navigate = useNavigate();
  const [dataSupervior, setDataSupervior] = useState([]);
  const [paginationSupervior, setPaginationSupervior] = useState({
    pageIndex: 1,
    pageSize: 100,
  });
  useEffect(() => {
    if (modalState.isConfirm) {
      form.setFieldsValue({ totalAmount: totalAmount });
    }
  }, [totalAmount]);

  useEffect(() => {
    const calAmountExpenseDetail = dataDetail?.calAmountExpenseDetail?.map(
      (item) => {
        return {
          id: item.id,
          categoryName: item.categoryName,
          categoryId: item.categoryId,
          amountInUse: item.amountInUse,
        };
      }
    );
    form.setFieldsValue({
      expenseDetails: calAmountExpenseDetail,
    });
  }, [isOpen]);

  useEffect(() => {
    if (processInit?.id) {
      fetchSuperviorProcessStep();
    }
  }, [processInit?.id]);

  const fetchSuperviorProcessStep = async () => {
    try {
      const res = await getSupervisorByProcessIdPaging({
        processStepId: processInit.id,
        groupId: dataDetail?.groupId,
        pageIndex:paginationSupervior.pageIndex,
        pageSize:paginationSupervior.pageSize,
      });

      if (res.code !== StatusResponse.SUCCESS)
        return message.error(res.message);
      setDataSupervior(res.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const fetchInitProcess = async () => {
    const resProcessType = await getInitProcessingStep(processTypeId);
    setProcessInit(resProcessType?.data);
  };
  useEffect(() => {
    getListProcessTypes(
      {
        expenseTypeId: PROCESSING_EXPENSE_CODE.SpendingHistory,
        purposeId: dataDetail?.purpose?.id,
      },
      setProcessTypeList
    );
  }, []);

  useEffect(() => {
    if (processTypeId) {
      fetchInitProcess();
    }
  }, [processTypeId]);

  const onFinish = async (value) => {
    setLoading(true);

    if (processInit?.isUploadBill && listImage.length === 0) {
      message.error("Vui lòng tải lên hình ảnh hoặc video!");
      setLoading(false);
      return;
    }

    if (processInit?.isUploadVoice && !voice) {
      message.error("Vui lòng cung cấp xác nhận bằng giọng nói");
      setLoading(false);
      return;
    }

    try {
      const expenseRequest = {
        ...value,
        id:  0,
        refId:  dataDetail.id,
        processTypeId: value.processTypeId,
        supervisorId: value.supervisorId,
        assignTo: dataDetail.assignTo,
        expenseTypeCode: "SpendingHistory",
        departmentId: dataDetail.departmentId,
        siteId: dataDetail.siteId,
        teamId:dataDetail.teamId,
        areaId:dataDetail.areaId,
        groupId: dataDetail.groupId,
        startDate: dataDetail.startDate,
        endDate: dataDetail.endDate,
        purposeId: dataDetail.purposeId,
        note: dataDetail.note,
        isLastReport:value?.isLastReport,
        bankName:value?.bankName,
        bankNote:value?.bankNote,
        accountName:value?.accountName,
        numberAccount:value?.numberAccount,
        totalAmount: totalAmount
      };

      const messInfor = {
        ...omit(value, ["expenseDetails", "totalAmount", "url"]),
        expenseId: dataDetail.id,
      };

      let expenseDetailAddOrEdit = null;
      let attachFiles = null;
      let attachUploadVoice = null

      expenseDetailAddOrEdit = {
          expenseDetailAdd: value.expenseDetails? value.expenseDetails.map((item) => {
            return {
              expenseId: 0,
              categoryId: item?.categoryId,
              amount: item?.amount,
              amountCamp: item?.amountCamp || 0,
              amountAdditional: item?.amountAdditional || 0,
            };
          }):null,
      };

      // Upload image, video
      const uploadedFiles = await uploadFileAsync(listImage);

     attachFiles = uploadedFiles.map((file) => {
       return {
          expenseId: dataDetail.id,
          filePath: file.data.filePath,
          fileType: file.data.fileType,
         description: file?.description,
        }})
      if (processInit?.isUploadVoice && voice) {
        await postFile({
          file: voice,
          fileType: "video/webm",
        }).then((res) => {
          attachUploadVoice = {
            expenseId: dataDetail.id,
            filePath: res?.data?.filePath,
            fileType: res?.data?.fileType,
            isVoice: true,
          };
        }).catch((err) => {console.log("Error uploadFile", err)});
      }
      const expenseDailyData = {
        expenseRequest,
        messInfor,
        expenseDetailAddOrEdit,
        attachFiles: attachUploadVoice !== null ? [...attachFiles, attachUploadVoice] : [...attachFiles],
      };

      const res = await createUpdatePurchaseRequest(expenseDailyData);
      if (!res.isError) {
        message.success(res.message);
        goToPage(res.data.expenseRequest.id, "BC", navigate)
      } else {
        message.error(res.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (file) => {
    setListImage(file.fileList);
  };

  const handleAmountChange = (changedValues, allValues) => {
    autoFillAmountFields(changedValues, allValues, form, setTotalAmount, false);
  };

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err)
  );

  const handleOnCancel = () => {
    if (loading) {
      return;
    }

    handleCancel();
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      width="700px"
      onCancel={handleOnCancel}
      footer={null}
    >
      <Divider className="mb-2" />
      <Spin size={"large"} spinning={loading}>
        <div className="mb-4 modal-body">
          <Form
            form={form}
            className="mx-2"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            onValuesChange={handleAmountChange}
            initialValues={{
              expenseDetails: dataDetail.expenseDetails.map((item) => {
                return { id: item.id, categoryId: item.categoryId };
              }),
            }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Chọn quy trình"
                  name="processTypeId"
                  rules={[{
                    required: true, message: "Vui lòng chọn quy trình!",
                  }]}
                >
                  <Select
                    allowClear
                    onChange={(e) => setProcessTypeId(e)}
                    placeholder="Chọn quy trình"
                    options={processTypeList?.map(process => ({
                      value: process.id,
                      label: process.name,
                    }))}
                  />
                </Form.Item>
              </Col>
              {processInit?.supervisorDepartmentId && (
                <Col span={24}>
                  <CardFormRequest title="Thông tin người phụ trách camp">
                    <SupervisorInfoForm dataSupervior={dataSupervior} />
                  </CardFormRequest>
                </Col>
              )}
              {processInit?.isAmount && (
                <Col span={24}>
                  <CardFormRequest title="Thông tin những danh mục đề xuất">
                    <AddCategoryForm dataDetail={dataDetail} totalAmount={totalAmount} isDailyReport={true} />
                  </CardFormRequest>
                </Col>
              )}
              {processInit?.isUploadBill && (
                <Col span={24}>
                  <CardFormRequest title="Thông tin hình ảnh">
                    <UploadImageForm listImage={listImage} form={form} handleChange={handleChange} />
                  </CardFormRequest>
                </Col>
              )}
              {processInit?.isUploadVoice && (
                <Col span={24}>
                  <CardFormRequest title="Xác nhận bằng giọng nói">
                    <UploadVoiceForm
                      recorderControls={recorderControls}
                      setVoice={setVoice}
                    />
                  </CardFormRequest>
                </Col>
              )}
              {(processTypeId && processInit?.isPartnerInfor) && (
                <Col span={24}>
                  <CardFormRequest className="overflow-auto mb-4" title="Thông tin đối tác">
                    <PartnerInfo />
                  </CardFormRequest>
                </Col>
              )}
              {processInit?.isBankInfor && (
                <Col span={24}>
                  <CardFormRequest title={"Thông tin ngân hàng"}>
                    <BankInfoForm />
                  </CardFormRequest>
                </Col>
              )}
              {processInit?.isMessInfor && (
                <Col span={24}>
                  <CardFormRequest title={"Báo cáo chi tiêu"}>
                    <FormDailyReportForm
                      dataDetail={dataDetail}
                      form={form}
                    />
                  </CardFormRequest>
                </Col>
              )}
              {(processTypeId && processInit?.isLastReport) && (
                <Col span={24}>
                  <CardFormRequest title="Báo cáo cuối cùng">
                    <LastReportForm />
                  </CardFormRequest>
                </Col>
              )}
            </Row>
            <Row justify="end" className="flex gap-4">
              <Button
                type="primary"
                danger
                className="w-[100px]"
                onClick={handleOnCancel}
              >
                Hủy bỏ
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
                className="w-[100px]"
              >
                Xác nhận
              </Button>
            </Row>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

export default memo(ModalDailyReportForm);