import React from "react";
import { Image, Layout, Menu } from "antd";
import {
  ApartmentOutlined,
  BankOutlined,
  CalculatorOutlined,
  DatabaseOutlined,
  FallOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  FolderOpenOutlined,
  GoldOutlined,
  PieChartOutlined,
  ProductOutlined,
  RiseOutlined,
  SnippetsOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { DEPARTMENT__CODE, ROLES, routes } from "@/utils/const";
import logo from "@/assets/images/logo.jpg";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import { isFullPermissionManageUser } from "@/utils/common";
import { HiOutlineUserGroup } from "react-icons/hi";

const { Sider } = Layout;

export const parentRoute = {
  [routes.home]: routes.home,
};

function SiderMain({ collapsed, setCollapsed }) {
  const navigate = useNavigate();
  const location = useLocation();
  const route = `/${location.pathname.split("/")[1]}`;
  const userInfor = useRecoilValue(infoUser);
  const roleCode = userInfor?.role?.roleCode;
  const departmentCode = userInfor?.department?.departmentCode;

  const items = [
    {
      key: "/home",
      icon: <PieChartOutlined style={{ fontSize: "20px" }} />,
      label: "Trang chủ",
      rolelevel: 0,
      isAllowed: true,
    },
    {
      key: "/figure",
      icon: <CalculatorOutlined style={{ fontSize: "20px" }} />,
      label: "Quản lý số liệu cược",
      rolelevel: 1,
      isAllowed: true,
    },
    {
      key: "/location",
      icon: <DatabaseOutlined style={{ fontSize: "20px" }} />,
      label: "Quản lý cấp bộ",
      rolelevel: 1,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
      children: [
        {
          key: "/sites",
          icon: <UnorderedListOutlined style={{ fontSize: "20px" }} />,
          label: "Quản lý trang",
          rolelevel: 1,
          isAllowed: [ROLES.ADMIN].includes(roleCode),
        },
        {
          key: "/departments",
          icon: <ApartmentOutlined style={{ fontSize: "20px" }} />,
          label: "Quản lý bộ phận",
          rolelevel: 1,
          isAllowed: [ROLES.ADMIN].includes(roleCode),
        },
        {
          key: "/areas",
          icon: <GoldOutlined style={{ fontSize: "20px" }} />,
          label: "Quản lý khu",
          rolelevel: 1,
          isAllowed: [ROLES.ADMIN].includes(roleCode),
        },
        {
          key: "/teams",
          icon: <TeamOutlined style={{ fontSize: "20px" }} />,
          label: "Quản lý tổ",
          rolelevel: 1,
          isAllowed: [ROLES.ADMIN].includes(roleCode),
        },
        {
          key: "/group",
          icon: <DatabaseOutlined style={{ fontSize: "20px" }} />,
          label: "Nhóm hậu đài",
          rolelevel: 1,
          isAllowed: [ROLES.ADMIN].includes(roleCode),
        },
      ]
    },
    {
      key: "/users",
      icon: <UserOutlined style={{ fontSize: "20px" }} />,
      label: "Quản lý nhân viên",
      rolelevel: 1,
      isAllowed: isFullPermissionManageUser(roleCode) || [DEPARTMENT__CODE.TLQC].includes(departmentCode),
    },
    {
      key: "/partners",
      icon: <HiOutlineUserGroup style={{ fontSize: "20px" }} />,
      label: "Quản lý đối tác",
      rolelevel: 1,
      isAllowed: ![ROLES.MEMBER].includes(roleCode),
    },
    {
      key: "/proposed-category",
      icon: <ProductOutlined style={{ fontSize: "20px" }} />,
      label: "Danh mục đề xuất",
      rolelevel: 1,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
    },
    {
      key: "/expense-request",
      icon: <BankOutlined style={{ fontSize: "20px" }} />,
      label: "Quản lý ngân sách",
      rolelevel: 0,
      isAllowed: true,
    },
    {
      key: "/increase-request",
      icon: <RiseOutlined style={{ fontSize: "20px" }} />,
      label: "Ứng thêm ngân sách",
      rolelevel: 0,
      isAllowed: true,
    },
    {
      key: "/decrease-request",
      icon: <FallOutlined style={{ fontSize: "20px" }} />,
      label: "Giảm ngân sách",
      rolelevel: 0,
      isAllowed: true,
    },
    {
      key: "/daily-report",
      icon: <SnippetsOutlined style={{ fontSize: "20px" }} />,
      label: "Quản lý báo cáo",
      rolelevel: 0,
      isAllowed: true,
    },
    {
      key: "/close-request",
      icon: <FileDoneOutlined style={{ fontSize: "20px" }} />,
      label: "Quản lý kết toán",
      rolelevel: 0,
      isAllowed: true,
    },
    {
      key: "/processing-steps",
      icon: <FolderOpenOutlined style={{ fontSize: "20px" }} />,
      label: "Quản lý quy trình",
      rolelevel: 4,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
    },
    {
      key: "/log-action",
      icon: <FileSearchOutlined style={{ fontSize: "20px" }} />,
      label: "Quản lý logs",
      rolelevel: 4,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
    },
  ];

  const visibleItems = items.filter((item) => item.isAllowed);

  const pRoute = parentRoute[route];

  const onClick = (e) => {
    navigate(e.key);
  };

  return (
    <Sider
      collapsible
      className="hidden lg:block"
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      theme="light"
      width={240}
      style={{
        position: "fixed",
        // borderRight: "1px solid #e0e0e0",
        zIndex: 10,
        minHeight: "100vh",
      }}
    >
      <div
        className="flex items-center justify-center h-[70px]"
        style={{ borderBottom: "1px solid #e0e0e0" }}
      >
        <Image src={logo} preview={false} className="p-2 max-h-[70px]" />
      </div>
      <Menu
        style={{
          borderInline: "none",
          backgroundColor: "transparent",
        }}
        mode="inline"
        defaultOpenKeys={[pRoute]}
        defaultSelectedKeys={[pRoute]}
        selectedKeys={[route]}
        className="text-[17px] bg-transparent mt-2 font-semibold"
        onClick={onClick}
        items={visibleItems}
      />
    </Sider>
  );
}

export default SiderMain;
