import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button, Divider, Dropdown, message, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { getDetailExpenseApi } from "@/services/base";
import { infoUser } from "@/recoil/atoms";
import { EXPENSE_TYPE_CODES, PROCESS_STATUS, PROCESSING_EXPENSE_CODE, ROLES } from "@/utils/const";
import { isFullPermission } from "@/utils/common";
import { allowCloseReq, getDateTimeOfNextMonth } from "@/utils/utils";
import { useNavigate } from "react-router";
import ModalExpenseDecreaseIncrease from "./ModalExpenseDecreaseIncrease";
import HistorySpending from "@/components/listRequset/HistorySpending";
import ModalFormIncreaseDecreaseBudget from "@/components/listRequset/ModalFormIncreaseDecreaseBudget";
import "./styles.css";
import ApproveModalForm from "@/components/expenseDetail/ApproveModalForm";
import CloseRequestForm from "@/components/closeRequest/CloseRequestForm";
import { fetchGroupSummary, getListProcessTypes, getSteps } from "@/utils/fetchData";
import ModalDailyReportForm from "@/components/listRequset/ModalDailyReportForm";
import GroupButtonProcess from "@/components/expenseDetail/GroupButtonProcess";
import ProposeCategoryDetail from "@/common/ProposeCategoryDetail";
import ProgressBar from "@/common/ProgressBar";
import ExpenseDetailInfo from "@/components/expenseDetail/ExpenseDetailInfo";
import MessageInfo from "@/common/MessageInfo";
import ApproveHistoryInfo from "@/common/ApproveHistoryInfo";

const ExpenseDetail = () => {
  const nav = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [dataDetail, setDataDetail] = useState([]);
  const [dailyModal, setDailyModal] = useState({
    isOpen: false,
    isConfirm: false,
    title: "",
    isSetMessageValue: false,
  });
  const [approveModal, setApproveModal] = useState({
    isOpen: false,
  });
  const [historyForm, setHistoryForm] = useState({
    isOpen: false,
  });
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState([]);
  const [processTypes, setProcessTypes] = useState([]);
  const [groupSummary, setGroupSummary] = useState();
  const refExpenseDecreaseIncrease = useRef(null);
  const refModalInDecrease = useRef(null);
  const [modalCloseRequest, setModalCloseRequest] = useState({
    isOpen: false,
  });
  const [isWithinDateRange, setIsWithinDateRange] = useState(false);
  const [modalTypeFormDeInCrease, setModalTypeFormDeInCrease] = useState("");
  const { id } = useParams();
  const infoState = useRecoilValue(infoUser);
  const [searchParams] = useSearchParams();
  const [isIncreaseBudgetForm, setIsIncreaseBudgetForm] = useState(
    Boolean(searchParams.get("increaseBudgetForm"))
  );
  const [isOpenIncreaseDecreaseModal, setIsOpenIncreaseDecreaseModal] =
    useState(false);

  useEffect(() => {
    if (id) getExpenseDetail(id);
  }, [id]);

  const handleCancel = () => {
    setDailyModal({ isOpen: false });
    setHistoryForm({ isOpen: false });
    setApproveModal({ isOpen: false });
    setModalCloseRequest({ isOpen: false });
  };

  const getExpenseDetail = async (id) => {
    setLoading(true);
    try {
      const expenseDetailCode = {
        "expense-request": EXPENSE_TYPE_CODES.Proposal,
        "increase-request": EXPENSE_TYPE_CODES.IncreaseAdj,
        "decrease-request": EXPENSE_TYPE_CODES.DecreaseAdj
      };

      const requestSegment = currentPath?.split('/')[1];
      
      const res = await getDetailExpenseApi(id, expenseDetailCode[requestSegment]);
      if (
        (res.code === 400 && res.error === -4) ||
        (res.code === 404 && res.error === -5)
      )
        return nav("/404");
      else if (res.code === 403) return nav("/forbidden");
      else if (res.code !== 200 && res.error !== 0)
        return message.error(res?.error);
      setDataDetail(res.data);

      // Open increaseForm
      if (isIncreaseBudgetForm) {
        setIsOpenIncreaseDecreaseModal(true);
        setModalTypeFormDeInCrease("up");
        const url = new URL(window.location.href);
        url.searchParams.delete("increaseBudgetForm");
        window.history.replaceState(null, "", url.toString());
        setIsIncreaseBudgetForm(false);
      }
      fetchGroupSummary(res?.data?.groupId, setGroupSummary);
      await getListProcessTypes(
        {
          expenseTypeId: PROCESSING_EXPENSE_CODE.RequestClose,
          purposeId: res?.data?.purpose?.id,
        },
        setProcessTypes
      );
      document.title = res.data.expenseType.name;
      const startDate = new Date(res.data.startDate);
      const endDateReport = getDateTimeOfNextMonth(res.data.endDate, 1);
      const currentDate = new Date();

      if (currentDate >= startDate && currentDate <= endDateReport) {
        setIsWithinDateRange(true);
      } else {
        setIsWithinDateRange(false);
      }

      await getSteps(res?.data?.processTypeId, setSteps);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessIncreaseDecrease = () => {
    getExpenseDetail(id);
    setIsOpenIncreaseDecreaseModal(false);
  };

  const renderBtn = () => {
    const isAssignee = dataDetail?.assignTo === infoState?.id;
    const isSupervisor = dataDetail?.supervisorId === infoState?.id;
    const fullPermission = isFullPermission(infoState?.role?.roleCode);
    const disableActionDecreaseRoleLevel =
      infoState?.role?.roleLevel > 1 ||
      infoState?.role?.roleLevel !== ROLES.ADMIN;
    const disableActionIncreaseRoleLevel =
      infoState?.role?.roleCode === ROLES.TL ||
      infoState?.role?.roleCode === ROLES.ADMIN;
    const finishExpenseRequest =
      dataDetail?.processStatus?.statusCode === PROCESS_STATUS.done;
    const supervisorLeaderIds = (dataDetail?.supervisorLeader || []).map(
      (item) => item.id
    );
    const allowedReport =
      (isSupervisor || 
        infoState?.role?.roleCode === ROLES.TL ||
        fullPermission ||
        supervisorLeaderIds.includes(infoState?.id)) &&
      finishExpenseRequest;

    const items = [
      {
        key: "2",
        label: (
          <a
            href
            onClick={() =>
              setDailyModal({
                isOpen: true,
                title: "BÁO CÁO CHI TIÊU HẰNG NGÀY",
              })
            }
          >
            Báo cáo chi tiêu
          </a>
        ),
        disabled: !(isWithinDateRange && allowedReport),
      },
      {
        key: "3",
        label: (
          <a href onClick={() => setHistoryForm({ isOpen: true })}>
            Lịch sử chi tiêu
          </a>
        ),
      },
      ...(dataDetail?.expenseType?.code === "DecreaseAdj" ||
      dataDetail?.expenseType?.code === "IncreaseAdj"
        ? [
            {
              key: "7",
              label: (
                <a href onClick={() => setModalCloseRequest({ isOpen: true })}>
                  Gửi yêu cầu kết toán
                </a>
              ),
              disabled: !(
                finishExpenseRequest &&
                (isAssignee || allowCloseReq(infoState, dataDetail))
              ),
            },
          ]
        : [
            {
              key: "4",
              label: (
                <a
                  href
                  onClick={() => {
                    refModalInDecrease.current.toggle();
                    setModalTypeFormDeInCrease("up");
                  }}
                >
                  Ứng thêm ngân sách
                </a>
              ),
              disabled: !finishExpenseRequest,
            },
            {
              key: "5",
              label: (
                <a
                  href
                  onClick={() => {
                    refModalInDecrease.current.toggle();
                    setModalTypeFormDeInCrease("down");
                  }}
                >
                  Giảm ngân sách
                </a>
              ),
              disabled:
                !finishExpenseRequest ||
                !disableActionDecreaseRoleLevel ||
                !disableActionIncreaseRoleLevel,
            },
            {
              key: "6",
              label: (
                <a
                  href
                  onClick={() => refExpenseDecreaseIncrease.current.toggle()}
                >
                  Xem đề xuất ứng thêm, giảm ngân sách
                </a>
              ),
            },
            {
              key: "7",
              label: (
                <a href onClick={() => setModalCloseRequest({ isOpen: true })}>
                  Gửi yêu cầu kết toán
                </a>
              ),
              disabled: !(
                finishExpenseRequest &&
                (isAssignee || allowCloseReq(infoState, dataDetail))
              ),
            },
          ]),
    ];

    return (
      !(
        dataDetail?.expenseType?.code === "DecreaseAdj" ||
        dataDetail?.expenseType?.code === "IncreaseAdj"
      ) && (
        <Dropdown
          menu={{
            items,
          }}
        >
          <Button
            type="primary"
            onClick={(e) => e.preventDefault()}
            className={`${
              dataDetail?.expenseType?.code === "IncreaseAdj" ? "hidden" : null
            }`}
          >
            Chức năng
            <DownOutlined />
          </Button>
        </Dropdown>
      )
    );
  };

  return (
    <>
      {loading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="mb-10">
          <ProgressBar
            dataDetail={dataDetail}
            steps={steps}
            title="Thông tin chi tiết ngân sách"
            renderBtn={renderBtn}
            reload={() => getExpenseDetail(id)}
          />
          <Divider />
          <ExpenseDetailInfo dataDetail={dataDetail} handleReload={() => getExpenseDetail(id)} />
          <ProposeCategoryDetail dataDetail={dataDetail} />
          <MessageInfo messInfor={dataDetail?.messInfor} />
          <ApproveHistoryInfo approvalHistory={dataDetail?.approvalHistory} />
          <GroupButtonProcess
            dataDetail={dataDetail}
            id={id}
            loading={loading}
            setApproveModal={setApproveModal}
            getExpenseDetail={getExpenseDetail}
            setLoading={setLoading}
          />
          <ModalExpenseDecreaseIncrease
            handleReloadExpendetail={getExpenseDetail}
            ref={refExpenseDecreaseIncrease}
          />
          <ModalFormIncreaseDecreaseBudget
            isOpen={isOpenIncreaseDecreaseModal}
            ref={refModalInDecrease}
            handleReload={() => handleSuccessIncreaseDecrease()}
            dataDetail={dataDetail}
            IncreaseDecreaseType={modalTypeFormDeInCrease}
          />
          {modalCloseRequest.isOpen && (
            <CloseRequestForm
              id={id}
              dataDetail={dataDetail}
              handleCancel={handleCancel}
              processTypes={processTypes}
              modalCloseRequest={modalCloseRequest}
              setLoadingForm={setLoading}
            />
          )}
          {approveModal.isOpen && (
            <ApproveModalForm
              IncreaseDecreaseType="up"
              groupSummary={groupSummary}
              approveModal={approveModal}
              handleCancel={handleCancel}
              dataDetail={dataDetail}
              handleReload={() => getExpenseDetail(id)}
            />
          )}
          {dailyModal.isOpen && (
            <ModalDailyReportForm
              dataDetail={dataDetail}
              modalState={dailyModal}
              handleCancel={handleCancel}
              setLoadingForm={setLoading}
            />
          )}
          {historyForm.isOpen && (
            <HistorySpending
              isModalOpen={historyForm.isOpen}
              handleCancel={handleCancel}
              dataDetail={dataDetail}
              handleReload={() => getExpenseDetail(id)}
              setLoadingForm={setLoading}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ExpenseDetail;
