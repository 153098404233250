import React, { useState } from "react";
import { Button, Col, Divider, Flex, Image, message, Modal, Popover, Row, Tag, Tooltip, Typography } from "antd";
import { PictureOutlined, MinusCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import GroupUserPopover from "@/components/popover/GroupUserPopover";
import ExpenseLink from "@/common/ExpenseLink";
import ImageCarousel from "@/components/carousel/ImageCarousel";
import { deleteFile } from "@/services/base";
import moment from "moment/moment";
import { formatCurrencyVND } from "@/utils/common";
import { addDays, getDateTimeOfNextMonth, getFileExtension, disableButtonsProcess } from "@/utils/utils";
import { useLocation } from "react-router-dom";
import { infoUser } from "@/recoil/atoms";
import { useRecoilValue } from "recoil";

const ExpenseDetailInfo = ({ dataDetail, handleReload }) => {
  const { processStatus: { statusCode } = {} } = dataDetail || {};
  const infoState = useRecoilValue(infoUser);
  const [isOpenFileModal, setIsOpenFileModal] = useState(false);
  const [selectedIndexFile, setSelectedIndexFile] = useState(false);
  const [listCarousel, setListCarousel] = useState([]);

  const { Text } = Typography;
  const location = useLocation();
  const route = location.pathname.split("/")[1];
  const endReportDate = getDateTimeOfNextMonth(dataDetail?.endDate, 1);
  const endApprovalDate = addDays(dataDetail?.startDate, 7);
  const expenseAttachFiles = dataDetail?.expenseAttachFiles;
  const attFiles = dataDetail?.attFiles;
  const suffixGroup = dataDetail?.purpose?.purposeCode === "Tiktok" ? "a" : "";
  const isFinish = ["Done", "Close", "Reject"].includes(statusCode);

  const hasPermisionInStep = !disableButtonsProcess(infoState, dataDetail);
  const isAllowDeleteImage = hasPermisionInStep && !isFinish;

  const handleDeleteFile = async (keyId) => {
    try {
      const res = await deleteFile({
        keyId: keyId,
        expenseId: dataDetail?.id
      });
      if (res.code === 200 && res.error === 0) {
        handleReload();
        message.success(res?.message);
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      message.error("Error: ", error);
    }
  }

  const confirmDeleteFile = (item, isVideo) => {
    Modal.confirm({
      title: "Xác nhận",
      content: `Bạn chắc chắn muốn xóa ${isVideo ? "video" : "hình"} này không?`,
      cancelText: "Hủy",
      onOk: async () => await handleDeleteFile(item?.id),

      cancelButtonProps: {
        type: "primary",
        danger: true,
      },
    });
  }

  const renderListFiles = (listFiles, isShowRemoveFile) => {
    return (
      <div className="flex mt-4 gap-4 flex-wrap">
        {listFiles?.map((item, index) => {
          const fileExtension = getFileExtension(item?.filePath);
          const isVideo = ["mp4", "webm", "ogg"].includes(fileExtension);
          if (item.isVoice === false) {
            const fileExtension = getFileExtension(item?.filePath);
            return (
              <Flex className="flex-col relative" key={index}>
                {(isAllowDeleteImage && isShowRemoveFile) && (
                  <Button
                    type="link"
                    className="absolute px-1 -top-5 -right-2 z-10 text-red-600 text-lg"
                    onClick={() => confirmDeleteFile(item, isVideo)}
                  >
                    <MinusCircleOutlined />
                  </Button>
                )}
                <div
                  className="w-[180px] h-[140px] overflow-hidden relative image-border flex items-center justify-center"
                  onClick={() => {
                    const listImages = attFiles?.filter(e => !e?.isVoice);
                    const indexFile = listImages?.findIndex((e) => e?.id === item?.id);

                    setListCarousel(listImages);
                    setSelectedIndexFile(indexFile);                
                    setIsOpenFileModal(true);
                  }}
                >
                  {isVideo ? (
                    <video
                      controls
                      className="absolute top-0 left-0 w-full h-full cursor-pointer"
                      autoPlay={false}
                      preload="metadata"
                      onClick={(event) => event.preventDefault()}
                    >
                      <source
                        src={`${process.env.REACT_APP_FILE}/${item?.filePath}`}
                        type={`video/${fileExtension}`}
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <Image
                      key={index}
                      src={`${process.env.REACT_APP_FILE}/${item?.filePath}`}
                      alt={item?.filePath}
                      className="w-full h-full object-cover cursor-pointer"
                      preview={false}
                    />
                  )}
                </div>
                <div className="italic mt-1 text-xs">{moment(item?.creationDate + "z").format("DD/MM/YYYY HH:mm")} </div>
                <div
                  className="min-h-[1rem] mt-2 max-w-[180px] break-words"
                  dangerouslySetInnerHTML={{ __html: item?.description }}
                />
              </Flex>
            );
          }
        })}
      </div>
    );
  };

  const singleFileExpenseOutput = renderListFiles(attFiles, true);

  const handleClickViewAllFile = () => {
    setListCarousel(expenseAttachFiles);
    setSelectedIndexFile(0);
    setIsOpenFileModal(true);
  }

  return (
    <>
      <ImageCarousel
        data={listCarousel?.filter(file => !file?.isVoice)}
        viewCarousel={isOpenFileModal}
        selectedIndex={selectedIndexFile}
        setViewCarousel={(value) => setIsOpenFileModal(value)}
      />
      <Row>
        <Col span={8}>
          <Text strong>Mã phiếu: </Text>
          <Tag>{`${dataDetail?.expenseType?.shortName}${dataDetail?.id}`}</Tag>
          {((route === "increase-request" || route === "decrease-request") && dataDetail.refId && dataDetail.refCode) && (
            <>
              <span>| </span>
              <Text strong>Mã phiếu gốc: </Text>
              <Tag
                className={"cursor-pointer"}
                color={"blue"}
              ><ExpenseLink id={dataDetail.refId} page="NS">{`${dataDetail.refCode}`}</ExpenseLink></Tag>
            </>
          )}
        </Col>

        <Col span={8}>
          <Text strong>Loại đề xuất: </Text>
          <Text>{dataDetail?.expenseType?.name}</Text>
        </Col>
        <Col span={8}>
          <Text strong>Quy trình: </Text>
          <Text>{dataDetail?.processType?.name}</Text>
        </Col>
      </Row>
      <Row className={"mt-4"}>
        <Col span={8}>
          <Text strong>Phiếu báo cáo: </Text>
          {dataDetail?.expenseSpendingHistory?.length > 0 ? (
            dataDetail?.expenseSpendingHistory?.map((item, index) => (
              <Tag
                className={"cursor-pointer text-blue-500 font-bold"}
                key={index}
              >
                <ExpenseLink id={item} page="BC">{`BC${item}`}</ExpenseLink>
              </Tag>
            ))
          ) : (
            <span className={"font-bold"}> Chưa có báo cáo</span>
          )}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>
          <Text strong>Nhân viên tạo ngân sách: </Text>
          <Text>{dataDetail?.userCreate?.fullName ?? "--"}</Text>
        </Col>
        <Col span={8}>
          <Text strong>Nhân viên được giao: </Text>
          <Text>
            {dataDetail?.userAssignTo?.fullName ?? "--"}
            <GroupUserPopover userGroup={dataDetail?.assignGroupUser} />
          </Text>
        </Col>
        <Col span={8}>
          <Text strong>Nhân viên phụ trách camp: </Text>
          <Text>{dataDetail?.userSupervisorCamp?.fullName ?? "--"}</Text>
        </Col>
      </Row>
      <Row className={"mt-4"}>
        <Col span={8}>
          <Text strong>Nhân viên xử lý: </Text>
          <Text>{dataDetail?.processingUser?.fullName || "--"}</Text>
        </Col>
        <Col span={8}>
          <Text strong>Nhóm hậu đài: </Text>
          <Text>{dataDetail?.group?.groupName}{suffixGroup}</Text>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>
          <Text strong>Loại hình quảng cáo: </Text>
          <Text>{dataDetail?.purpose?.purposeName}</Text>
        </Col>
        <Col span={8}>
          <Text strong>Ghi chú: </Text>
          <Text>{dataDetail?.note}</Text>
        </Col>
        <Col span={8}>
          <Text strong>Số page: </Text>
          <Text>{dataDetail?.numOfPage}</Text>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>
          <Text strong>Thời gian tạo: </Text>
          <Text>
            {moment(dataDetail?.creationDate + "z").format("DD/MM/YYYY HH:mm")}
          </Text>
        </Col>
        <Col span={8}>
          <Text strong>Thời gian sử dụng ngân sách: </Text>
          <Text>
            {" "}
            Từ {moment(dataDetail?.startDate + "").format("DD/MM/YYYY")}
          </Text>
          <Text>
            {" "}
            đến {moment(dataDetail?.endDate + "").format("DD/MM/YYYY")}
          </Text>
          <Tooltip
            overlayClassName="!min-w-[270px]"
            title={
              <>
                <div>
                  Hạn chót báo cáo:{" "}
                  {moment(endReportDate).format("DD/MM/YYYY HH:mm")}
                </div>
                <div>
                  Hạn chót phê duyệt:{" "}
                  {moment(endApprovalDate).format("DD/MM/YYYY HH:mm")}
                </div>
              </>
            }
          >
            <QuestionCircleOutlined className="ml-2" />
          </Tooltip>
        </Col>
        <Col span={8}>
          <Text strong>Thời gian yêu cầu thực tại: </Text>
          <Text>
            {" "}
            Từ{" "}
            {moment(dataDetail?.calAmountExpenseRequest?.startDate + "").format(
              "DD/MM/YYYY"
            )}
          </Text>
          <Text>
            {" "}
            đến{" "}
            {moment(dataDetail?.calAmountExpenseRequest?.endDate + "").format(
              "DD/MM/YYYY"
            )}
          </Text>
          <Tooltip
            overlayClassName="!min-w-[270px]"
            title={
              <div>
                <Text className={"text-white"}>
                  Đây là thời gian thực tại tổng hợp của tất cả các phiếu bao
                  gồm ngày của phiếu cha và tổng hợp kết thúc là ngày cuối cùng
                  của những phiếu con
                </Text>
              </div>
            }
          >
            <QuestionCircleOutlined className="ml-2" />
          </Tooltip>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        {dataDetail?.partner?.partnerFullName && (
          <Col span={8}>
            <Text strong>Tên đối tác: </Text>
            <Text>{dataDetail?.partner?.partnerFullName ?? "--"}</Text>
          </Col>
        )}
        {dataDetail?.partner?.partnerAccount && (
          <Col span={8}>
            <Text strong>Tài khoản đối tác: </Text>
            <Text>{dataDetail?.partner?.partnerAccount ?? "--"}</Text>
          </Col>
        )}
        {dataDetail?.partner?.phoneTele && (
          <Col span={8}>
            <Text strong>Telegram: </Text>
            <Text>{dataDetail?.partner?.phoneTele ?? "--"}</Text>
          </Col>
        )}
        {dataDetail?.partner?.phoneZalo && (
          <Col span={8}>
            <Text strong>Zalo: </Text>
            <Text>{dataDetail?.partner?.phoneZalo ?? "--"}</Text>
          </Col>
        )}
        <Col span={8}>
          <Text strong>Tên ngân hàng: </Text>
          <Text>{dataDetail?.bankName ?? "--"}</Text>
        </Col>
        <Col span={8}>
          <Text strong>Tên tài khoản: </Text>
          <Text>{dataDetail?.accountName ?? "--"}</Text>
        </Col>
        <Col span={8}>
          <Text strong>Số tài khoản: </Text>
          <Text>{dataDetail?.numberAccount ?? "--"}</Text>
        </Col>
        <Col span={8}>
          <Text strong>Nội dung chuyển khoản: </Text>
          <Text>{dataDetail?.bankNote ?? "--"}</Text>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>
          <Text strong>Ngân sách hỗ trợ: </Text>
          <span className={"font-bold"}>
            {dataDetail?.isSupportPackage ? "Có" : "Không"}
          </span>
        </Col>
        {dataDetail?.expenseType?.shortName === "DXT" && (
          <Col span={8}>
            <Text strong>Phê duyệt đặc biệt: </Text>
            <span className={"font-bold"}>
              {dataDetail?.isSpecialApprove ? "Có" : "Không"}
            </span>
          </Col>
        )}
      </Row>
      <Divider />
      {dataDetail?.expenseType?.code === "IncreaseAdj" && (
        <>
          <Row>
            <Col span={8}>
              <Text strong>Số tiền ứng thêm ngân sách: </Text>
              <Text>{formatCurrencyVND(dataDetail?.totalAmount || 0)}</Text>
            </Col>
          </Row>
          <Divider />
        </>
      )}
      <Row>
        <Col span={24}>
          <Flex align="center" gap={"10px"}>
            <Text className="block" strong>
              Hình ảnh:{" "}
            </Text>
            {expenseAttachFiles?.length > 0 && (
              <Button
                type="primary"
                icon={<PictureOutlined />}
                size="medium"
                onClick={() => handleClickViewAllFile()}
              />
            )}
          </Flex>
          {singleFileExpenseOutput}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Text strong>Voice xác nhận của Tổ trưởng:</Text>
      </Row>
      <div className="flex items-center gap-4 overflow-hidden">
        {dataDetail?.attFiles?.map((item, index) => {
          if (item.isVoice === true) {
            const fileExtension = getFileExtension(item?.filePath);
            return (
              fileExtension === "webm" && (
                <video
                  key={index}
                  controls
                  className="h-[70px] w-[30%]"
                  autoPlay={false}
                  preload="metadata"
                >
                  <source
                    src={`${process.env.REACT_APP_FILE}/${item?.filePath}`}
                    type={`video/${fileExtension}`}
                  />
                  Your browser does not support the video tag.
                </video>
              )
            );
          }
        })}
      </div>
      <Divider />
    </>
  );
};

export default ExpenseDetailInfo;
