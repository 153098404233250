import CompForm from "@/common/CompForm";
import { createGroup, updateGroup } from "@/services/base";
import { StatusResponse } from "@/utils/const";
import { Form, Input, message, Modal, Select, Switch } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { getListAreas, getListTeams } from "@/utils/fetchData";
import { handleClearLoadMore, handleLoadMore, handleSearchLoadMore, transformData } from "@/utils/utils";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { allDepartments, allSites } from "@/recoil/atoms";

function GroupFormAction({ stateModal, closeModal, reloadPage }) {
  const sites = useRecoilValue(allSites);
  const departments = useRecoilValue(allDepartments);
  const { isOpenModal, isCreate, titleModal, buttonOkModal, editData } = stateModal;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [areas, setAreas] = useState([]);
  const [areaParams, setAreaParams] = useState({
    siteId: editData?.siteId,
    departmentId: editData?.departmentId
  });
  const [teamParams, setTeamParams] = useState({
    siteId: editData?.siteId,
    areaId: editData?.areaId,
    departmentId: editData?.departmentId
  });

  useEffect(() => {
    getListAreas(areaParams, setAreas, setAreaParams);
  }, [areaParams.pageSize, areaParams.textSearch, areaParams.siteId, areaParams.departmentId]);

  useEffect(() => {
    getListTeams(teamParams, setTeams, setTeamParams);
  }, [teamParams.pageSize, teamParams.textSearch, teamParams.siteId, teamParams.areaId, teamParams.departmentId]);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
        siteId: {
          label: editData?.site?.siteName,
          value: editData?.siteId,
        },
        departmentId: {
          label: editData?.department?.departmentName,
          value: editData?.departmentId,
        },
        areaId: {
          label: editData?.area?.name,
          value: editData?.areaId,
        },
        teamId: {
          label: editData?.team?.name,
          value: editData?.teamId,
        },
      });

      setAreaParams((prev) => ({
        ...prev,
        siteId: editData?.siteId,
        departmentId: editData?.departmentId,
      }));
      setTeamParams((prev) => ({
        ...prev,
        siteId: editData?.siteId,
        departmentId: editData?.departmentId,
        areaId: editData?.areaId
      }));
    }
    return () => form.resetFields();
  }, [isOpenModal, editData, form]);

  const onFinish = useCallback(async (fieldValues) => {
    const data = transformData(fieldValues);
    setLoading(true);
    try {
      const action = isCreate ? createGroup : updateGroup;
      const res = await action(data);

      if (res.code !== StatusResponse.SUCCESS) {
        message.error(res.message);
        return;
      }

      message.success(
        isCreate
          ? "Tạo mới nhóm hậu đài thành công"
          : "Cập nhật nhóm hậu đài thành công."
      );
      reloadPage();
      closeModal();
    } catch (error) {
      console.error("Error in onFinish:", error);
    } finally {
      setLoading(false);
    }
  },
  [isCreate, reloadPage, closeModal]
  );

  const filterOption = useCallback(
    (input, option) => option?.label.toLowerCase().includes(input.toLowerCase()),
    []
  );

  const handleChangeSite = (value) => {
    setAreaParams((prev) => ({
      ...prev,
      siteId: value
    }));
    setTeamParams((prev) => ({
      ...prev,
      siteId: value
    }));

    form.resetFields(["departmentId", "areaId", "teamId"]);
  }

  const handleChangeDepartment = (value) => {
    setAreaParams((prev) => ({
      ...prev,
      departmentId: value
    }));
    setTeamParams((prev) => ({
      ...prev,
      departmentId: value
    }));

    form.resetFields(["areaId", "teamId"]);
  }

  const handleChangeArea = (value) => {
    setTeamParams((prev) => ({
      ...prev,
      areaId: value
    }));

    form.resetFields(["teamId"]);
  }

  const formItemsConfig = [
    {
      name: "id",
      hidden: true,
      component: <Input hidden />,
    },
    {
      label: "Tên nhóm hậu đài",
      name: "groupName",
      rules: [
        { required: true, message: "Tên nhóm hậu đài không được bỏ trống!" },
      ],
      component: <Input placeholder="Nhập tên nhóm hậu đài ..." />,
    },
    {
      label: "Trang",
      name: "siteId",
      rules: [{ required: true, message: "Vui lòng chọn trang!" }],
      component: (
        <Select
          allowClear
          showSearch
          placeholder="Chọn trang"
          filterOption={(input, option) =>
            option?.label.toLowerCase().includes(input.toLowerCase())
          }
          onChange={handleChangeSite}
          options={sites.map((site) => ({
            value: site.id,
            label: site.siteName
          }))}
        />
      ),
    },
    {
      label: "Bộ phận",
      name: "departmentId",
      rules: [{ required: true, message: "Vui lòng chọn bộ phận!" }],
      component: (
        <Select
          allowClear
          showSearch
          placeholder="Chọn bộ phận"
          filterOption={(input, option) =>
            option?.label.toLowerCase().includes(input.toLowerCase())
          }
          onChange={handleChangeDepartment}
          options={departments.map((department) => ({
            value: department.id,
            label: department.departmentName
          }))}
        />
      ),
    },
    {
      label: "Khu",
      name: "areaId",
      rules: [{ required: true, message: "Vui lòng chọn khu!" }],
      component: (
        <Select
          allowClear
          showSearch
          placeholder="Khu"
          onSearch={(e) => handleSearchLoadMore(e, setAreaParams)}
          onClear={handleClearLoadMore(setAreaParams)}
          onChange={handleChangeArea}
          filterOption={(input, option) =>
            option?.label.toLowerCase().includes(input.toLowerCase())
          }
          options={areas.map((area) => ({
            value: area.id,
            label: area.areaName
          }))}
          onPopupScroll={handleLoadMore(areaParams, setAreaParams)}
        />
      ),
    },
    {
      label: "Chọn Tổ",
      name: "teamId",
      rules: [{ required: true, message: "Vui lòng chọn Tổ!" }],
      component: (
        <Select
          allowClear
          showSearch
          placeholder="Chọn tổ"
          filterOption={filterOption}
          onSearch={(e) => handleSearchLoadMore(e, setTeamParams)}
          onClear={handleClearLoadMore(setTeamParams)}
          options={teams.map((team) => ({
            value: team.id,
            label: team.teamName
          }))}
          onPopupScroll={handleLoadMore(teamParams, setTeamParams)}
        />
      ),
    },
    {
      label: "Nhóm đặc biệt",
      name: "isSupper",
      component: (
        <Switch />
      ),
      tooltip: {
        title: "Nhóm đặc biệt sẽ không tính dữ liệu tiêu chí hậu đài!",
        icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
      },
      className: "!mt-8",
    },
  ];

  return (
    <Modal
      title={titleModal}
      open={isOpenModal}
      width="600px"
      onCancel={closeModal}
      footer={null}
    >
      <CompForm
        loading={loading}
        buttonOkModal={buttonOkModal}
        onFinish={onFinish}
        form={form}
        formItemsConfig={formItemsConfig}
        activeBtnSub={true}
      />
    </Modal>
  );
}

export default GroupFormAction;
