import ButtonPrimary from "@/common/ButtonPrimary";
import DataTable from "@/common/DataTable";
import AreaFilterForm from "@/components/area/AreaFilterForm";
import { deleteArea } from "@/services/base";
import { StatusResponse } from "@/utils/const";
import { Divider, message, Modal, Popconfirm, Typography, } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import AreaFormAction from "@/components/area/AreaFormAction";
import moment from "moment";
import CountData from "@/common/CountData";
import { getListAreas } from "@/utils/fetchData";
import GroupActionButton from "@/common/GroupActionButton";

const { confirm } = Modal;

const initStateModal = {
  isOpenModal: false,
  isCreate: false,
  titleModal: "",
  buttonOkModal: "",
  editData: {},
};

const initParams = {
  textSearch: "",
  pageIndex: 1,
  pageSize: 10,
}

const initPagination = {
  pageIndex: 1,
  pageSize: 10,
  total: 0,
  pages: 1,
}

const AreaPage = () => {
  document.title = "Quản lý khu";
  const [areas, setAreas] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stateModal, setStateModal] = useState(initStateModal);
  const [searchParams, setSearchParams] = useState(initParams);
  const [paginationAreas, setPaginationAreas] = useState(initPagination);

  useEffect(() => {
    getListAreas(searchParams, setAreas, setPaginationAreas);
  }, [searchParams]);

  const handlechangeSearch = useCallback((key, value) => {
    setSearchParams(prev => ({ ...prev, [key]: value, pageIndex: 1 }));
  }, []);

  const onchangePage = useCallback((pageIndex, pageSize) => {
    setSearchParams(prev => ({ ...prev, pageIndex, pageSize }));
  }, []);

  const createArea = () => {
    setStateModal({
      isOpenModal: true,
      isCreate: true,
      titleModal: "Thêm mới khu",
      buttonOkModal: "Thêm",
      editData: null,
    });
  };

  const closeModal = () => {
    setStateModal(initStateModal);
  };

  const updateArea = (data) => {
    setStateModal({
      isOpenModal: true,
      isCreate: false,
      titleModal: "Chỉnh sửa khu",
      buttonOkModal: "Chỉnh sửa",
      editData: data,
    });
  };

  const onDeleteArea = async (id) => {
    setLoading(true);
    try {
      const res = await deleteArea(id);
      if (res.code === StatusResponse.SUCCESS) {
        message.success("Xóa khu thành công");
        reloadPage();
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.error("Error deleting area:", error);
    } finally {
      setLoading(false);
    }
  };

  const showModalConfirmDelete = (record) => {
    confirm({
      title: "Bạn có chắc chắn muốn xóa?",
      icon: <ExclamationCircleFilled/>,
      content: "Khi xóa sẽ không thể hoàn tác!",
      okText: "Xóa",
      okType: "primary",

      cancelText: "Hủy",
      onOk() {
        onDeleteArea(record);
      },
      onCancel() {},
      cancelButtonProps: {
        type: 'primary',
        danger: true
      }
    });
  };

  const columns = [
    {
      title: "Tên khu",
      key: "areaName",
      dataIndex: "areaName",
    },
    {
      title: "Trang",
      key: "site",
      dataIndex: "site",
      render: (site) => {
        return site?.siteName;
      },
    },
    {
      title: "Bộ phận",
      key: "department",
      dataIndex: "department",
      render: (depart) => {
        return depart?.departmentName;
      },
    },
    {
      title: "Thời gian tạo",
      key: "creationDate",
      dataIndex: "creationDate",
      render: (creationDate) => {
        return (
          <Typography.Text>
            {moment(creationDate + "z").format("DD/MM/YYYY HH:mm")}
          </Typography.Text>
        );
      },
    },
    {
      title: "Hành động",
      key: "action",
      render: (_, record) => (
        <GroupActionButton record={record} showModalDelete={showModalConfirmDelete} updateFn={updateArea}/>
      ),
    },
  ];

  const reloadPage = () => {
    getListAreas(initParams, setAreas, setPaginationAreas);
    setSearchParams(initParams);
    setSelectedAreas([]);
  }

  const deleteMultipleAreas = async () => {
    setLoading(true);
    try {
      const results = await Promise.all(
        selectedAreas.map(dep => deleteArea(dep).catch(err => {
          console.error(`Failed to delete area ${dep}:`, err);
          return { isError: true, message: err.message };
        }))
      );

      const deleteFails = results.filter(res => res.isError).map(res => res.message);

      if (deleteFails.length > 0) {
        deleteFails.forEach(item => message.error(item));
      } else {
        message.success("Thành công");
      }

      reloadPage();
    } catch (err) {
      console.error("Error in deleteMultipleAreas:", err);
    } finally {
      setLoading(false);
    }
  };

  const handlePopconfirm = {
    confirm: deleteMultipleAreas,
    cancle: () => {},
  };

  return (
    <>
      <section className="flex justify-between mb-4">
        <AreaFilterForm onchangeSearch={handlechangeSearch} />
        <div className="flex gap-3">
          {selectedAreas.length > 0 && (
            <Popconfirm
              title="Xóa khu"
              placement="topLeft"
              description="Bạn có thật sự muốn xóa những khu đã chọn, điều này không thể hoàn tác?"
              onConfirm={handlePopconfirm.confirm}
              onCancel={handlePopconfirm.cancle}
              okText="Xác nhận"
              cancelText="Hủy"
              cancelButtonProps={{
                type: 'primary',
                danger: true
              }}
            >
              <Typography.Text
                className="bg-red-500 hover:bg-red-600 text-white flex items-center px-2 rounded-lg cursor-pointer"
                size="middle"
                style={{ border: "none" }}
              >
                Xóa {selectedAreas.length} khu đã chọn
              </Typography.Text>
            </Popconfirm>
          )}
          <ButtonPrimary text="Thêm khu" onClick={createArea}/>
        </div>
      </section>
      <CountData loading={loading} total={paginationAreas.total} text="khu"/>
      <Divider/>
      <DataTable
        columns={columns}
        dataSource={areas}
        currentPage={searchParams.pageIndex}
        pageSize={searchParams.pageSize}
        totalPage={paginationAreas.total}
        loading={loading}
        selectedRowKeys={selectedAreas}
        setSelectedRowKeys={setSelectedAreas}
        onPageChange={onchangePage}
        scroll={{ y: 530 }}
      />
      <AreaFormAction
        stateModal={stateModal}
        closeModal={closeModal}
        reloadPage={reloadPage}
      />
    </>
  );
};

export default AreaPage;
