import React, { memo, useEffect, useState } from "react";
import { Button, Col, Divider, Form, message, Modal, Row, } from "antd";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import { CheckExpenseTypeCode, ROLES, } from "@/utils/const";
import { createUpdatePurchaseRequest, getInitProcessingStep, postFile, } from "@/services/base";
import { fetchGroupSummary } from "@/utils/fetchData";
import { omit } from "lodash";
import dayjs from "dayjs";
import "./styles.css";
import CardFormRequest from "@/common/FormCardRequest";
import FormDailyReportForm from "@/components/listRequset/FormDailyReportFormRequest";
import { useAudioRecorder } from "react-audio-voice-recorder";
import SupervisorInfoForm from "@/components/form/SupervisorInfoForm";
import FigureSiteInfor from "@/common/FigureSiteInfor";
import UploadImageForm from "@/components/form/UploadImageForm";
import UploadVoiceForm from "@/components/form/UploadVoiceForm";
import BankInfoForm from "@/components/form/BankInfoForm";
import AddCategoryForm from "@/components/form/AddCategoryForm";
import InfoGeneralForm from "@/components/form/InfoGeneralForm";
import TotalAmountCreateForm from "@/components/form/TotalAmountCreateForm";
import SpecialApprovalForm from "@/components/form/SpecialApprovalForm";
import PriorityNotificationForm from "@/components/form/PriorityNotificationForm";
import { autoFillAmountFields, uploadFileAsync } from "@/utils/utils";
import PartnerInfo from "@/components/form/PartnerInfo";

const FormRequest = ({
  modalState,
  handleCancel,
  handleReload,
  loadingForm,
  setLoadingForm,
}) => {
  const { isModalOpen, typeForm, title } = modalState;
  const [form] = Form.useForm();
  const infoUserState = useRecoilValue(infoUser);
  const [totalAmount, setTotalAmount] = useState(0);
  const [listImage, setListImage] = useState([]);
  const [groupSummary, setGroupSummary] = useState();
  const [processTypeId, setProcessTypeId] = useState(null);
  const [dataSupervior, setDataSupervior] = useState([]);
  const [processInit, setProcessInit] = useState(null);
  const [voice, setVoice] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(infoUserState?.groupId);
  const [isSupperGroup, setIsSupperGroup] = useState(false);
  const [isApproveSpecial, setIsApproveSpecial] = useState(false);
  const handleDataSuperviorChange = (newValue) => {
    setDataSupervior(newValue);
  };

  const handleProcessInitChange = (newValue) => {
    setProcessInit(newValue);
  };

  const handleProcessTypeIdChange = (newValue) => {
    setProcessTypeId(newValue);
  };

  const handleSelectedGroupChange = (newValue) => {
    setSelectedGroupId(newValue?.value === undefined ? null : newValue.value);
    setIsSupperGroup(newValue?.isSupper);
  };

  const fetchInitProcess = async () => {
    const resProcessType = await getInitProcessingStep(processTypeId);
    setProcessInit(resProcessType?.data);
  };

  useEffect(() => {
    if (processTypeId) {
      fetchInitProcess();
    }
  }, [processTypeId]);
  useEffect(() => {
    fetchGroupSummary(selectedGroupId, setGroupSummary);
  }, [selectedGroupId]);

  useEffect(() => {
    form.setFieldsValue({ totalAmount: totalAmount });
  }, [totalAmount, form]);

  const handleAmountChange = (changedValues, allValues) => {
    autoFillAmountFields(changedValues, allValues, form, setTotalAmount, true)
  };

  const handleSubmitSuccess = () => {
    handleReload();
    form.resetFields();
    setLoadingForm(false);
    handleCancel();
    setListImage([]);
  };


  const buildExpenseDetailAddOrEdit = (value) => {
    const expenseDetailAdd = [];
    const expenseDetailEdit = [];

    value?.expenseDetails?.forEach((expenseDetail) => {
      if (expenseDetail.id) {
        expenseDetailEdit.push({
          id: expenseDetail.id,
          expenseId: expenseDetail.expenseId || 0,
          categoryId: expenseDetail.categoryId || 0,
          amount: expenseDetail.amount || 0,
          amountCamp: expenseDetail.amountCamp || 0,
          amountAdditional: expenseDetail.amountAdditional || 0
        });
      } else {
        expenseDetailAdd.push({
          expenseId: expenseDetail.expenseId || 0,
          categoryId: expenseDetail.categoryId || 0,
          amount: expenseDetail.amount || 0,
          amountCamp: expenseDetail.amountCamp || 0,
          amountAdditional: expenseDetail.amountAdditional || 0
        });
      }
    });
    return {
      expenseDetailAdd:
        expenseDetailAdd.length > 0 ? expenseDetailAdd : undefined,
      expenseDetailEdit:
        expenseDetailEdit.length > 0 ? expenseDetailEdit : undefined,
    };
  };

  const onFinish = async (value) => {
    setLoadingForm(true);
    const formatDate = (date, addDays = 0) =>
      dayjs(date).add(addDays, "day").toISOString();

    const startDate = formatDate(value?.timeUseBudget && value?.timeUseBudget[0], 1);
    const endDate = formatDate(value.timeUseBudget && value.timeUseBudget[1], 1);
    try {
      // Upload image, video
      const uploadedFiles = await uploadFileAsync(listImage);

      let messInfor = {
        expenseId: 0,
        quantityExistAccount: value?.quantityExistAccount || null,
        quantityResponse: value?.quantityResponse || null,
        quantityIncome: value?.quantityIncome || null,
        quantityClosing: value?.quantityClosing || null,
        quantityReference: value?.quantityReference || null,
        quantityTemp: value?.quantityTemp || null,
      }
      const bankInfor = {
        bankName: value?.bankName || null,
        accountName: value?.accountName || null,
        numberAccount: value?.numberAccount || null,
        bankNote: value?.bankNote || null,
      };
      const attachFiles = uploadedFiles.map((file) => {
        return {
          expenseId: 0,
          filePath: file.data.filePath,
          fileType: file.data.fileType,
          description: file?.description,
        };
      });

      let attachUploadVoice = null;
      if (processInit?.isUploadVoice && voice) {
        await postFile({
          file: voice,
          fileType: "video/webm",
        }).then((res) => {
          attachUploadVoice = {
            expenseId: 0,
            filePath: res?.data?.filePath,
            fileType: res?.data?.fileType,
            isVoice: true,
          };
        }).catch((err) => {
          console.log("Error uploadFile", err);
        });
      }

      let expenseRequest = {
        ...omit(value, ["timeUseBudget", "url", "expenseDetails"]),
        id: 0,
        refId: null,
        expenseTypeCode: CheckExpenseTypeCode(typeForm),
        startDate,
        endDate,
        ...bankInfor,
        isSupportPackage: value?.isSupportPackage,
        numOfPage: value?.numOfPage,
      };

      const data = {
        expenseRequest,
        expenseDetailAddOrEdit: buildExpenseDetailAddOrEdit(value),
        attachFiles:
          attachUploadVoice !== null
            ? [...attachFiles, attachUploadVoice]
            : [...attachFiles],
        messInfor: processInit?.isMessInfor ? messInfor : null,
        isTopNotification: value?.isTopNotification
      };
      const res = await createUpdatePurchaseRequest(data);
      if (!res.isError) {
        message.success('Tạo ngân sách thành công!');
        handleSubmitSuccess();
      } else {
        message.error(res.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingForm(false);
    }
  };

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err)
  );

  const handleChange = (file) => {
    setListImage(file.fileList);
  };

  const handleOnCancel = () => {
    if (loadingForm) {
      return;
    }

    handleCancel();
  }

  return (
    <Modal
      title={title}
      open={isModalOpen}
      width="1000px"
      onCancel={handleOnCancel}
      footer={null}
    >
      <Divider/>
      <div className="modal-body px-2">
        <Form
          layout="vertical"
          className="mx-2"
          form={form}
          onFinish={onFinish}
          onValuesChange={handleAmountChange}
          initialValues={{
            expenseDetails: [{ categoryId: undefined, amount: "" }],
            url: [],
            siteId: infoUserState?.site?.id || null,
            teamId: infoUserState?.teamId || null,
            areaId: infoUserState?.areaId || null,
            departmentId: infoUserState?.department?.id || null,
            groupId: infoUserState?.group?.id || null,
            timeUseBudget: [],
            assignTo:
              infoUserState.role.roleCode === ROLES.MEMBER
                ? infoUserState.id
                : undefined,
          }}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <CardFormRequest title="Thông tin chung của phiếu">
                <InfoGeneralForm
                  form={form}
                  processInit={processInit}
                  selectedGroupId={selectedGroupId}
                  modalState={modalState}
                  totalAmount={totalAmount}
                  handleDataSuperviorChange={handleDataSuperviorChange}
                  handleProcessTypeIdChange={handleProcessTypeIdChange}
                  handleProcessInitChange={handleProcessInitChange}
                  handleSelectedGroupChange={handleSelectedGroupChange}
                />
              </CardFormRequest>
            </Col>
            <Col span={24}>
              <Row gutter={[10, 10]}>
                {processInit?.isAmount && (
                  <Col span={24}>
                    <CardFormRequest title="Thông tin những danh mục đề xuất">
                      <AddCategoryForm />
                      <TotalAmountCreateForm
                        totalAmount={totalAmount}
                        groupSummary={groupSummary}
                        isSpecial={isSupperGroup}
                        specialApproval={isApproveSpecial}
                      />
                      <FigureSiteInfor
                        groupSummary={groupSummary}
                        totalAmount={totalAmount}
                        isSpecial={isSupperGroup}
                      />
                    </CardFormRequest>
                  </Col>
                )}
                {processInit?.supervisorDepartmentId && (
                  <Col span={12}>
                    <CardFormRequest title="Thông tin người phụ trách camp">
                      <SupervisorInfoForm dataSupervior={dataSupervior}/>
                    </CardFormRequest>
                  </Col>
                )}
                {processInit?.isUploadVoice && (
                  <Col span={12}>
                    <CardFormRequest title="Xác nhận bằng giọng nói">
                      <UploadVoiceForm
                        recorderControls={recorderControls}
                        setVoice={setVoice}
                      />
                    </CardFormRequest>
                  </Col>
                )}
                {processInit?.isPartnerInfor && (
                  <Col span={12}>
                    <CardFormRequest className="overflow-auto mb-4" title="Thông tin đối tác">
                      <PartnerInfo />
                    </CardFormRequest>
                  </Col>
                )}
                {processInit?.isBankInfor && (
                  <Col span={12}>
                    <CardFormRequest title={"Thông tin ngân hàng"}>
                      <BankInfoForm/>
                    </CardFormRequest>
                  </Col>
                )}
                {processInit?.isMessInfor && (
                  <Col span={12}>
                    <CardFormRequest title="Báo cáo chi tiêu">
                      <FormDailyReportForm
                        form={form}
                        dataSupervior={dataSupervior}
                      />
                    </CardFormRequest>
                  </Col>
                )}
                {processInit?.isSpecialApprove && (
                  <Col span={12}>
                    <CardFormRequest className="overflow-auto mb-4" title="Phê duyệt đặc biệt">
                      <SpecialApprovalForm setIsApproveSpecial={setIsApproveSpecial}/>
                    </CardFormRequest>
                  </Col>
                )}
                {processInit?.isTopNotification && (
                  <Col span={12}>
                    <CardFormRequest title="Thông báo ưu tiên">
                      <PriorityNotificationForm />
                    </CardFormRequest>
                  </Col>
                )}
                {processInit?.isUploadBill && (
                  <Col span={24}>
                    <CardFormRequest title="Thông tin hình ảnh">
                      <UploadImageForm listImage={listImage} form={form} handleChange={handleChange}/>
                    </CardFormRequest>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <Row justify="end" className="flex gap-4">
            <Button type="primary" danger onClick={handleCancel}>
              Hủy bỏ
            </Button>
            <Button type="primary" htmlType="submit" loading={loadingForm} disabled={loadingForm}>
              Lưu lại
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
export default memo(FormRequest);
