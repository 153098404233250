//Auth
export const API_AUTH_LOGIN = "/auth/login";
export const API_AUTH_REFRESH_TOKEN = "/auth/refresh-token";
export const API_CHANGE_PASS = "/auth/change-password";

// Dashboard
export const API_GET_SUMARY_TICKET_STAUS =
  "/summaryexpense/sumary-ticket-status";
export const API_GET_SUMARY_TICKET_YEAR = "/summaryexpense/sumary-ticket-year";
export const API_GET_SUMARY_TICKET_MONTH =
  "/summaryexpense/sumary-ticket-month";

//User
export const API_USER = "/user";
export const API_GET_PAGING_USERS = "/user/get-page";
export const API_CREATE_USER = "/user/add";
export const API_UPDATE_USER = "/user/update";
export const API_UPDATE_PROFILE = "/user/updateprofile";
export const API_IMPORT_USER = "/user/import-file";
export const API_EXPORT_USER = "/user/export-file";

//Sites
export const API_GET_PAGING_SITES = "/sites";
export const API_GET_LIST_SITES = "/sites/list";
export const API_SITES = "/sites";

//Expense Request
export const API_GET_LIST = "/expenserequest/get-page";
export const API_UPDATE_EXPENSE = "/expenserequest/update";
export const API_CREATE_EXPENSE = "/expenserequest/add";
export const API_DELETE_EXPENSE = "/expenserequest";
export const API_GET_DETAIL_EXPENSE = "/expenserequest";
export const API_GET_EXPENSE_DETAIL_CHILD =
  "/expenserequest/getpage-requestadj";
export const API_EXPENSE_APPROVE = "/expenserequest/approve";
export const API_EXPENSE_REJECT = "/expenserequest/reject";
export const API_EXPENSE_PENDING = "/expenserequest/pending";
export const API_EXPENSE_CLOSE = "/expenserequest/request-close";
export const API_EXPENSE_ACTIVE = "/expenserequest/active";
export const API_EXPENSE_NEXT = "/expenserequest/next";
export const API_EXPENSE_GENERATE = "/expenserequest";
export const API_EXPENSE_TYPE = "/expensetype/get-list";
export const API_UPDATE_SUPERVISOR = "/expenserequest/update-supervisor";
export const API_REQUEST_CLOSE = "/expenserequest/get-page-request-close";
export const API_EXPENSE_PROCESSING_USER = "/expenserequest/processinguser";
export const API_EXPENSE_EXPORT_FILE = "/expenserequest/export-file";
export const API_EXPENSE_EXPORT_ALL_FILE = "/expenserequest/export-all-file";
export const API_ROLLBACK_DAILY_REPORT = "/expenserequest/rollback-daily-report";

// Proposed category
export const GET_PROPOSED_CATEGORY_LIST = "/category/get-page";
export const ADD_PROPOSED_CATEGORY = "/category/add";
export const UPDATE_PROPOSED_CATEGORY = "/category/update";
export const DELETE_PROPOSED_CATEGORY = "/category";

//Roles
export const API_ROLES = "/userrole";
export const API_GET_PAGING_ROLES = "/userrole/get-page";

//Departments
export const API_GET_PAGING_DEPARTMENTS = "/department/get-page";
export const API_GET_LIST_DEPARTMENT_TYPE =
  "/department/get-page-department-type";
export const API_DEPARTMENT = "/department";
export const API_ADD_DEPARTMENT = "/department/add";
export const API_UPDATE_DEPARTMENT = "/department/update";

// Area
export const API_AREA = "/area";
export const API_GET_LIST_AREA = "/area/get-page";
export const API_ADD_AREA = "/area/add";
export const API_UPDATE_AREA = "/area/update";

// ProcessingStep
export const API_GET_PAGING_PROCESSINGSTEPS = "/processingstep/get-page";
export const API_GET_LIST_PROCESSINGSTEPS = "/processingstep/get-list";
export const API_CREATE_PROCESSINGSTEPS = "/processingstep/add";
export const API_UPDATE_PROCESSINGSTEPS = "/processingstep/update";
export const API_PROCESSINGSTEPS = "/processingstep";
export const API_PROCESSINGSTEPS_SUPERVISOR =
  "/processingstep/get-supervisor-by-processid";

export const API_PROCESSINGSTEPS_GET_INIT_PROCESS =
  "/processingstep/get-init-process";

export const API_PROCESSINGSTEPS_GETSUPERVISOR_BY_PROCESSID =
  "/processingstep/get-supervisor-by-processid";

// ProcessingStepType
export const API_GET_PROCESS_TYPE = "/processtype/get-list";
export const API_ADD_PROCESS_TYPE = "/processtype/add";
export const API_UPDATE_PROCESS_TYPE = "/processtype/update";
export const API_DELETE_PROCESS_TYPE = "/processtype/delete";
export const API_COPY_PROCESS_TYPE = "/processtype/copy";

// Purpose
export const API_GET_PURPOSE = "/processtype/get-list-purpose";

// ProcessStatus
export const API_GET_PROCESS_STATUS = "/processstatus/get-list";

// ExpenseDetail
export const API_CREATE_EXPENSE_DETAIL = "/expensedetail/add";

// MessInfo
export const API_GET_MESS_INFO = "/messinfor/gets";
export const API_CREATE_UPDATE_MESS_INFO = "/messinfor/add-or-update";
export const API_DELETE_MESS_INFO = "/messinfor";

// Team
export const API_TEAM = "/team";
export const API_GET_TEAM_LIST = "/team/get-page";
export const API_ADD_TEAM = "/team/add";
export const API_UPDATE_TEAM = "/team/update";

// Log
export const API_GET_LOG = "/logaction/getlogactions";
export const API_UPDATE_EXPENSE_DETAIL = "/expensedetail/update";
export const API_DELETE_EXPENSE_DETAIL = "/expensedetail/delete";

//  File UPLOAD
export const API_UPLOAD_FILE = "/fileupload/uploadfile";

// Notification
export const API_NOTIFICATION = "/notification/get-page";
export const API_VIEW_NOTIFICATION = "/notification/mark-as-viewed";
export const API_DELETE_NOTIFICATION = "/notification/delete";

export const API_SUBSCRIBE = "/subscriptions/subscribe";
export const API_DELETE_SUBSCRIPTION = "subscriptions/deletesubscription";

// PurchaseRequest
export const ADD_EDIT_PURCHASE_REQUEST = "/purchaserequest/add-and-edit";
export const API_DELETE_FILE = "/purchaserequest"
export const GET_DAILY_REPORT = "/expenserequest/get-page-daily-report";

// Decrease Request
export const API_DECREASE_REQUEST = "/expenserequest/get-page-dec-adj";

// Increase Request
export const API_INCREASE_REQUEST = "/expenserequest/get-page-inc-adj";

// Figure
export const API_FIGURE = "/groupsummary";
export const API_GET_FIGURE = "/groupsummary/get-page";
export const API_IMPORT_FILE = "/groupsummary/import-file";
export const API_EXPORT_FILE = "/groupsummary/export-file";

// Group Summary
export const API_GROUP_SUMMARY = "/groupsummary";

// Target Config
export const API_GET_TARGET_CONFIG = "/targetconfig/get-page";
export const API_UPDATE_TARGET_CONFIG = "/targetconfig/update";

// Group
export const API_GET_GROUP = "/group";
export const API_GROUP_EXPORT_FILE = "/group/export-file";
export const API_GROUP_IMPORT_FILE = "/group/import-file";

// Partner
export const API_PARTNER = "/partner";
export const API_GET_PARTNER_LIST = "/partner/get-page";
export const API_ADD_PARTNER = "/partner/add";
export const API_UPDATE_PARTNER = "/partner/update";
