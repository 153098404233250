import React, { useState } from 'react';
import { Button, Col, Divider, Flex, Image, message, Modal, Row, Tag, Typography } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import ExpenseLink from "@/common/ExpenseLink";
import ImageCarousel from "@/components/carousel/ImageCarousel";
import moment from "moment/moment";
import { getFileExtension, disableButtonsProcess } from "@/utils/utils";
import { deleteFile } from "@/services/base";
import { useLocation } from "react-router";
import { infoUser } from "@/recoil/atoms";
import { useRecoilValue } from "recoil";

const TicketInfo = ({ dataDetail, handleReload }) => {
  const { processStatus: { statusCode } = {} } = dataDetail || {};
  let location = useLocation();
  const infoState = useRecoilValue(infoUser);
  const [isOpenFileModal, setIsOpenFileModal] = useState(false);
  const [selectedIndexFile, setSelectedIndexFile] = useState(false);

  const isDailyReportPage = location.pathname.split("/")[1] === "daily-report";
  const isCloseRequestPage = location.pathname.split("/")[1] === "close-request";
  const suffixGroup = dataDetail?.purpose?.purposeCode === "Tiktok" ? "a" : "";

  const hasPermisionInStep = !disableButtonsProcess(infoState, dataDetail);
  const isFinish = ["Done", "Close", "Reject"].includes(statusCode);
  const isAllowDeleteImage = hasPermisionInStep && !isFinish;

  const handleDeleteFile = async (keyId) => {
    try {
      const res = await deleteFile({
        keyId: keyId,
        expenseId: dataDetail?.id
      });
      if (res.code === 200 && res.error === 0) {
        handleReload();
        message.success(res?.message);
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      message.error("Error: ", error);
    }
  }

  const confirmDeleteFile = (item, isVideo) => {
    Modal.confirm({
      title: "Xác nhận",
      content: `Bạn chắc chắn muốn xóa ${isVideo ? "video" : "hình"} này không?`,
      cancelText: "Hủy",
      onOk: async () => await handleDeleteFile(item?.id),

      cancelButtonProps: {
        type: "primary",
        danger: true,
      },
    });
  }

  return (
    <Row gutter={[16, 16]}>
      <ImageCarousel
        data={dataDetail?.attFiles?.filter(file => !file?.isVoice)}
        viewCarousel={isOpenFileModal}
        selectedIndex={selectedIndexFile}
        setViewCarousel={(value) => setIsOpenFileModal(value)}
      />

      <Col span={8}>
        <Typography.Text strong>Mã phiếu: </Typography.Text>
        <Tag>{`${dataDetail?.expenseType.shortName}${dataDetail?.id}`}</Tag>
      </Col>
      <Col span={8}>
        <Typography.Text strong>Mã phiếu gốc: </Typography.Text>
        <Tag color="blue" className="cursor-pointer" title={`Đi tới trang ngân sách ${dataDetail?.refId}`}>
          <ExpenseLink id={dataDetail?.refId} page="NS">{`NS${dataDetail?.refId}`}</ExpenseLink>
        </Tag>
      </Col>
      <Col span={8}>
        <Typography.Text strong>Quy trình: </Typography.Text>
        <Typography.Text>{dataDetail?.processType?.name}</Typography.Text>
      </Col>
      <Divider className="my-1"/>
      <Col span={8}>
        <Typography.Text strong>Nhân viên báo cáo: </Typography.Text>
        <Typography.Text>{dataDetail?.userCreate?.fullName || "Trống"}</Typography.Text>
      </Col>
      <Col span={8}>
        <Typography.Text strong>Nhân viên được giao: </Typography.Text>
        <Typography.Text>{dataDetail?.userAssignTo?.fullName || "Trống"}</Typography.Text>
      </Col>
      <Col span={8}>
        <Typography.Text strong>Nhân viên phụ trách camp: </Typography.Text>
        <Typography.Text>{dataDetail?.userSupervisorCamp?.fullName || "Trống"}</Typography.Text>
      </Col>
      <Col span={8}>
        <Typography.Text strong>Nhân viên xử lý: </Typography.Text>
        <Typography.Text>{dataDetail?.processingUser?.fullName  || "Trống"}</Typography.Text>
      </Col>
      <Col span={8}>
        <Typography.Text strong>Nhóm hậu đài: </Typography.Text>
        <Typography.Text>{dataDetail?.group?.groupName}{suffixGroup}</Typography.Text>
      </Col>
      {isDailyReportPage && (
        <>
          <Divider className="my-1"/>
          <Col span={8}>
            <Typography.Text strong>Xác nhận camp cuối cùng: </Typography.Text>
            <Typography.Text>
              {dataDetail?.isLastReport ? "Có" : "Không"}
            </Typography.Text>
          </Col>
        </>
      )}
      <Divider className="my-1"/>
      <Col span={8}>
        <Typography.Text strong>Thời gian báo cáo: </Typography.Text>
        <Typography.Text>{moment(dataDetail?.creationDate + "z").format("DD/MM/YYYY HH:mm")}</Typography.Text>
      </Col>
      <Col span={8}>
        <Typography.Text strong>Thời gian sử dụng ngân sách: </Typography.Text>
        <Typography.Text>
          {" "}
          Từ{" "}
          {moment(dataDetail?.startDate + "").format("DD/MM/YYYY")}
        </Typography.Text>
        <Typography.Text>
          {" "}
          đến{" "}
          {moment(dataDetail?.endDate + "").format("DD/MM/YYYY")}
        </Typography.Text>
      </Col>
      <Divider className="my-1"/>
      {isCloseRequestPage && (
        <>
          {dataDetail?.partner?.partnerFullName && (
            <Col span={8}>
              <Typography.Text strong>Tên đối tác: </Typography.Text>
              <Typography.Text>{dataDetail?.partner?.partnerFullName ?? "--"}</Typography.Text>
            </Col>
          )}
          {dataDetail?.partner?.partnerAccount && (
            <Col span={8}>
              <Typography.Text strong>Tài khoản đối tác: </Typography.Text>
              <Typography.Text>{dataDetail?.partner?.partnerAccount ?? "--"}</Typography.Text>
            </Col>
          )}
          {dataDetail?.partner?.phoneTele && (
            <Col span={8}>
              <Typography.Text strong>Telegram: </Typography.Text>
              <Typography.Text>{dataDetail?.partner?.phoneTele ?? "--"}</Typography.Text>
            </Col>
          )}
          {dataDetail?.partner?.phoneZalo && (
            <Col span={8}>
              <Typography.Text strong>Zalo: </Typography.Text>
              <Typography.Text>{dataDetail?.partner?.phoneZalo ?? "--"}</Typography.Text>
            </Col>
          )}
          <Col span={8}>
            <Typography.Text strong>Tên ngân hàng: </Typography.Text>
            <Typography.Text>{dataDetail?.bankName ?? '--'}</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text strong>Tên tài khoản: </Typography.Text>
            <Typography.Text>{dataDetail?.accountName ?? '--'}</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text strong>Số tài khoản: </Typography.Text>
            <Typography.Text>{dataDetail?.numberAccount ?? '--'}</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text strong>Nội dung chuyển khoản: </Typography.Text>
            <Typography.Text>{dataDetail?.bankNote ?? '--'}</Typography.Text>
          </Col>
        </>
      )}

      {dataDetail?.attFiles?.length > 0 && (
        <>
          <Divider className="my-1"/>
          <Col span={24}>
            <Typography.Text className="block" strong>
              Hình ảnh:{" "}
            </Typography.Text>
            <div className="flex items-center mt-4 gap-4 flex-wrap">
              {dataDetail?.attFiles?.map((item, index) => {
                const fileExtension = getFileExtension(item?.filePath);
                const isVideo = ['mp4', 'webm', 'ogg'].includes(fileExtension);
                if (item.isVoice === false) {
                  const fileExtension = getFileExtension(item?.filePath);
                  return (
                    <Flex className="flex-col relative" key={index}>
                      {isAllowDeleteImage && (
                        <Button
                          type="link"
                          className="absolute px-1 -top-5 -right-2 z-10 text-red-600 text-lg"
                          onClick={() => confirmDeleteFile(item, isVideo)}
                        >
                          <MinusCircleOutlined />
                        </Button>
                      )}
                      <div
                        className="w-[180px] h-[140px] overflow-hidden relative image-border flex items-center justify-center"
                        onClick={() => {
                          setSelectedIndexFile(index);
                          setIsOpenFileModal(true);
                        }}
                      >
                        {isVideo ? (
                          <video
                            controls
                            className="absolute top-0 left-0 w-full h-full cursor-pointer"
                            autoPlay={false}
                            preload="metadata"
                            onClick={(event) => event.preventDefault()}
                          >
                            <source
                              src={`${process.env.REACT_APP_FILE}/${item?.filePath}`}
                              type={`video/${fileExtension}`}
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <Image
                            key={index}
                            src={`${process.env.REACT_APP_FILE}/${item?.filePath}`}
                            alt={item?.filePath}
                            className="w-full h-full object-cover cursor-pointer"
                            preview={false}
                          />
                        )}
                      </div>
                      <div className="italic mt-1 text-xs">{moment(item?.creationDate + "z").format("DD/MM/YYYY HH:mm")} </div>
                      <div className="min-h-[1rem] mt-2 max-w-[180px] break-words"
                           dangerouslySetInnerHTML={{ __html: item?.description }}/>
                    </Flex>
                  )
                }
              })}
            </div>
          </Col>
        </>
      )}
    </Row>
  );
};

export default TicketInfo;