import React, { useState } from "react";
import { ArrowLeftOutlined, CheckCircleOutlined, RollbackOutlined } from "@ant-design/icons";
import { Flex, Row, Steps, Tooltip, Typography, Button, message, Modal, Form, Input } from "antd";
import { useNavigate } from "react-router";
import TagStatus from "@/components/listRequset/TagStatus";
import { processingUserExpense, rollbackDailyReport } from "@/services/base";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import { isAdmin, isTLB, isTLQC } from "@/utils/utils";

const ProgressBar = ({ dataDetail, steps, title, renderBtn, reload, type = '' }) => {
  const navigate = useNavigate();
  const [processUserLoading, setProcessUserLoading] = useState(false);
  const { processStatus: { statusCode } = {} } = dataDetail || {};
  const isShowProcessUser = ["Done", "Close", "Reject"].includes(statusCode);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userInfor = useRecoilValue(infoUser);

  const isAllowedRollback =
    type === 'daily-report'
    && statusCode === 'Done'
    && (isAdmin(userInfor) || isTLQC(userInfor) || isTLB(userInfor));

  const renderProgress = () => {
    const currentStepPriority = dataDetail?.processStep?.stepPriority;    

    const getStatus = (step) => {
      const stepId = step.stepPriority;
      if (currentStepPriority === stepId) {
        switch (statusCode) {
          case "New":
          case "Inprogress":
            return "process";
          case "Pending":
            return "process";
          case "Aproved":
          case "Done":
          case "Close":
            return "finish";
          default:
            return "error";
        }
      } else if (currentStepPriority > stepId) {
        return "finish";
      } else {
        return "wait";
      }
    };

    return steps?.map((step) => ({
      title: <Tooltip title={step.note}>{step.stepName}</Tooltip>,
      status: getStatus(step),
    }));
  };

  const handleProcessingUserExpense = async () => {
    try {
      setProcessUserLoading(true);

      const res = await processingUserExpense(dataDetail?.id);
      if (res.isError) {
        message.error(res.message);
        setProcessUserLoading(false);
        return;
      }

      message.success(res?.message);
      reload();
    } catch (error) {
      message.error("Error: " + error);
    } finally {
      setProcessUserLoading(false);
    }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);

      const data = {
        expenseRequestId: dataDetail?.id,
        processNote: values.processNote,
      };

      const res = await rollbackDailyReport(data);
      if (res.isError) {
        message.error(res.message);
        return;
      }

      message.success(res?.message);
      reload();
    } catch (error) {
      if (error.errorFields) {
        console.log("Validation Error:", error.errorFields);
        message.error("Vui lòng điền lý do hoàn tác!");
      } else {
        message.error("Error: " + error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRollback = async () => {
    Modal.confirm({
      title: "Bạn có chắc chắn muốn hoàn tác về bước trước khi phê duyệt báo cáo?",
      content: (
        <Form form={form}>
          <Form.Item
            name="processNote"
            rules={[
              { required: true, message: "Vui lòng điền lý do hoàn tác!" },
            ]}
          >
            <Input.TextArea placeholder="Điền lý do hoàn tác..." rows={2} />
          </Form.Item>
        </Form>
      ),
      okText: "Xác nhận",
      okType: "primary",
      cancelText: "Hủy",
      onOk: handleOk,
      okButtonProps: {
        loading: loading,
        className: "custom-yellow-button",
      },
      cancelButtonProps: {
        type: "primary",
        danger: true,
      },
    });
  };

  return (
    <>
      <Flex>
        {window.history.length > 1 && (
          <div>
            <span
              title="Trở về"
              className="p-2 block cursor-pointer hover:opacity-80 -m-[2px]"
              onClick={() => navigate(-1)}
            >
              <ArrowLeftOutlined />
            </span>
          </div>
        )}
        <Steps className="mx-4" labelPlacement="vertical" items={renderProgress()} />
      </Flex>
      <Row className="flex justify-between mt-10">
        <Flex>
          <Typography.Title level={3} className="inline-block !my-0">{title}</Typography.Title>
          <TagStatus className="px-3 py-1 text-[1rem] ml-4" record={dataDetail} />
          {!isShowProcessUser && (
            <Button
              className="mr-2"
              loading={processUserLoading}
              type="primary"
              size="medium"
              icon={<CheckCircleOutlined />}
              onClick={handleProcessingUserExpense}
            >
              Tiếp nhận xử lý
            </Button>
          )}
          {isAllowedRollback && (
            <Button
              loading={loading}
              onClick={handleRollback}
              type="primary"
              className="custom-yellow-button"
            >
              Hoàn tác <RollbackOutlined />
            </Button>
          )}
        </Flex>
        {renderBtn && renderBtn()}
      </Row>
    </>
  );
};

export default ProgressBar;
