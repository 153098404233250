import React, { Suspense, useEffect, useState } from "react";
import { createBrowserRouter, Navigate, RouterProvider, } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Spin } from "antd";
import BasicLayout from "@/layouts/BasicLayout";
import AuthenticationLayout from "@/layouts/Authentication";
import Login from "@/page/Auth/Login";
import { getAccessTokenBase, isFullPermissionManageUser, setAccessTokenBase, } from "@/utils/common";
import { infoUser } from "@/recoil/atoms";
import { DEPARTMENT__CODE, ROLES } from "@/utils/const";
import Home from "@/page/Home";
import Users from "@/page/Users";
import SitesPage from "@/page/Sites";
import AreaPage from "@/page/Area";
import DepartmentPage from "@/page/Departments";
import ListRequest from "@/page/List-request";
import AccountInfo from "@/components/users/AccountInfo";
import ServerErrors from "@/components/errors/ServerErrors";
import ProcessingSteps from "@/page/ProcessingStep";
import ProposedCategory from "src/page/ProposedCategory";
import ExpenseDetail from "@/page/expense-detail";
import LogAction from "@/page/LogAction";
import InitSignalR from "@/signalr/init";
import registerServiceWorker from "@/wokers/registerServiceWorker";
import HistorySpendingDetail from "@/page/HistoryExpenseDetail";
import DailyReport from "@/page/DailyReport";
import TeamsPage from "@/page/Teams";
import NotFound from "../errors/NotFound";
import DecreaseRequest from "@/page/DecreaseRequest";
import IncreaseRequest from "@/page/IncreaseRequest";
import CloseRequest from "@/page/CloseRequest";
import CloseRequestDetail from "@/components/closeRequest/CloseRequestDetail";
import Figure from "@/page/Figure";
import Forbidden from "@/components/errors/Forbidden";
import GroupPage from "@/page/GroupPage";
import Partner from "src/page/Partners";

const Providers = () => {
  const dataUser = useRecoilValue(infoUser);
  const roleCode = dataUser?.role?.roleCode;
  const userToken = getAccessTokenBase();
  const [firstCheck, setFirstCheck] = useState(false);
  const departmentCode = dataUser?.department?.departmentCode;

  const routes = [
    { path: "/home", element: <Home />, isAllowed: true },
    {
      path: "/users",
      element: <Users />,
      isAllowed: isFullPermissionManageUser(roleCode) || [DEPARTMENT__CODE.TLQC].includes(departmentCode),
    },
    {
      path: "/partners",
      element: <Partner />,
      isAllowed: ![ROLES.MEMBER].includes(roleCode),
    },
    {
      path: "/group",
      element: <GroupPage />,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
    },
    {
      path: "/sites",
      element: <SitesPage />,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
    },
    {
      path: "/figure",
      element: <Figure />,
      isAllowed: true,
    },
    {
      path: "/teams",
      element: <TeamsPage />,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
    },
    {
      path: "/areas",
      element: <AreaPage />,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
    },
    {
      path: "/departments",
      element: <DepartmentPage />,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
    },
    {
      path: "/processing-steps",
      element: <ProcessingSteps />,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
    },
    { path: "/users/account-info", element: <AccountInfo />, isAllowed: true },
    {
      path: "/expense-request",
      element: <ListRequest />,
      isAllowed: true },
    { path: "/daily-report", element: <DailyReport />, isAllowed: true },
    {
      path: "/proposed-category",
      element: <ProposedCategory />,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
    },
    {
      path: "/expense-request/:id",
      element: <ExpenseDetail />,
      isAllowed: true,
    },
    {
      path: "/close-request/:id",
      element: <CloseRequestDetail />,
      isAllowed: true,
    },
    {
      path: "/increase-request/:id",
      element: <ExpenseDetail />,
      isAllowed: true,
    },
    {
      path: "/decrease-request/:id",
      element: <ExpenseDetail />,
      isAllowed: true,
    },
    {
      path: "/close-request",
      element: <CloseRequest />,
      isAllowed: true,
    },
    {
      path: "/increase-request",
      element: <IncreaseRequest />,
      isAllowed: true,
    },
    {
      path: "/decrease-request",
      element: <DecreaseRequest />,
      isAllowed: true,
    },
    {
      path: "/daily-report/:id",
      element: <HistorySpendingDetail />,
      isAllowed: true,
    },
    {
      path: "/log-action",
      element: <LogAction />,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
    },
    { path: "/server-erros", element: <ServerErrors />, isAllowed: true },
    { path: "/server-errors", element: <ServerErrors />, isAllowed: true },
  ];

  const filteredRoutes = routes.filter((route) => route.isAllowed);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <BasicLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={"/home"} replace />,
        },
        {
          path: "*",
          element: <Navigate to={"/home"} replace />,
        },
        {
          path: "/404",
          element: <NotFound />,
        },
        {
          path: "/forbidden",
          element: <Forbidden />,
        },
        ...filteredRoutes,
      ],
    },
  ]);

  const authRouter = createBrowserRouter([
    {
      path: "/",
      element: <AuthenticationLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={"/login"} replace />,
        },
        {
          path: "*",
          element: <Navigate to={"/login"} replace />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/server-errors",
          element: <ServerErrors />,
        },
        {
          path: "/404",
          element: <NotFound />,
        },
      ],
    },
  ]);

  useEffect(() => {
    const token = getAccessTokenBase();

    if (!!token) {
      setAccessTokenBase(token);
      registerServiceWorker(dataUser?.id);
    }
    setFirstCheck(true);
  }, [dataUser]);

  if (!firstCheck) return null;

  return (
    <Suspense
      fallback={
        <div className="h-screen flex flex-col justify-center items-center space-y-6 animate-pulse">
          <img src="/logo.jpg" alt="Logo"/>
          <div className="font-sans font-bold text-3xl text-[#333]">
            Hệ thống ứng ngân sách...
            <Spin className="ml-4" size="large"/>
          </div>
        </div>
      }
    >
      <RouterProvider
        router={!!userToken ? router : authRouter}
        fallbackElement={<Spin size="large" />}
      />
      <InitSignalR />
    </Suspense>
  );
};

export default Providers;
