import TagStatus from "@/components/listRequset/TagStatus";
import { formatCurrencyVND } from "@/utils/common";
import { Flex, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState, } from "react";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import { useParams } from "react-router";
import { getListIncreaseRequestAdj } from "@/services/base";
import DataTable from "@/common/DataTable";
import ExpenseLink from "@/common/ExpenseLink";
import FormSearch from "@/common/FormSearch";
import ExportExpense from "@/common/ExportExpense";
import CountData from "@/common/CountData";
import { QuestionCircleOutlined } from "@ant-design/icons";

const IncreaseRequest = () => {
  document.title = "Quản lý ứng thêm ngân sách";
  const infoUserState = useRecoilValue(infoUser);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [searchParams, setSearchParam] = useState({
    textSearch: "",
    expenseId: id,
    pageIndex: 1,
    pageSize: 10,
  });
  const [totalPage, setTotalPage] = useState(0);
  const [dataRequestAdj, setDataRequestAdj] = useState([]);

  useEffect(() => {
    fetchDataListExpenseAdj(searchParams);
  }, [searchParams]);

  const fetchDataListExpenseAdj = async (params) => {
    try {
      setLoading(true);
      const res = await getListIncreaseRequestAdj(params);
      if (res.code === 200 && res.error === 0) {
        setDataRequestAdj(res.data.map((u) => ({ ...u, key: u.id })));
        setTotalPage(res?.pagination?.total);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const renderStatusCol = (value) => {
    return <TagStatus className="m-0" record={value}/>;
  };

  const columns = [
    {
      title: "Mã phiếu",
      dataIndex: "id",
      key: "id",
      width: 85,
      fixed: 'left',
      render: (id, record) => (
        <ExpenseLink id={id} page="DXT">{`${record.expenseType.shortName}${id}`}</ExpenseLink>
      ),
    },
    {
      key: "refId",
      title: "Mã NS",
      dataIndex: "refId",
      width: 85,
      align: 'center',
      render: redId => (
        <ExpenseLink id={redId} page="NS">{`NS${redId}`}</ExpenseLink>
      ),
    },
    {
      title: "Loại hình",
      dataIndex: "purpose",
      width: 120,
      key: "purpose",
      render: (value, rec) => {
        const purposeName = value?.purposeName || "--";
        const isNVL = value?.purposeCode === "NVL" && rec?.numOfPage;

        if (isNVL) {
          return (
            <>
              {purposeName} - <span className="text-[#1677ff]">{rec?.numOfPage} page</span>
            </>
          );
        }

        return purposeName;
      },
    },
    {
      title: <span>Số tiền <Tooltip title="Số tiền ứng thêm ngân sách"><QuestionCircleOutlined /></Tooltip></span>,
      dataIndex: "totalAmount",
      width: 130,
      key: "totalAmount",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng ngân sách",
      dataIndex: "calculateAmount",
      width: 130,
      key: "calculateAmount",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Nhóm HD",
      dataIndex: "group",
      key: "group",
      render: (value) => (value ? value.groupName : "--"),
    },
    {
      title: <span>Thời gian <Tooltip title="Thời gian bắt đầu sử dụng ngân sách"><QuestionCircleOutlined /></Tooltip></span>,
      dataIndex: "startDate",
      key: "startDate",
      width: 110,
      render: (value) => moment(value + "z").format("DD/MM/YYYY"),
    },
    {
      title: "QUY TRÌNH",
      children: [
        {
          title: "Tên quy trình duyệt",
          dataIndex: "processType",
          key: "processType",
          render: (value) => (value?.name ? value?.name : "--"),
        },
        {
          title: "Bước xử lý",
          dataIndex: "processStep",
          key: "processStep",
          render: (value, rec) => {
            const isProcessingCurrentUser = infoUserState?.departmentId == value?.departmentId;
            const stepName = value?.stepName ? value?.stepName : "--";
            const allowStatus = ["Inprogress", "Pending"].includes(rec?.processStatus?.statusCode);
            return isProcessingCurrentUser && allowStatus ? (
              <Tag color="blue">{stepName}</Tag>
            ) : (
              stepName
            );
          },
        },
      ]
    },
    {
      title: "Trạng thái",
      dataIndex: "processStatus",
      key: "processStatus",
      align: 'center',
      render: (_, rec) => renderStatusCol(rec),
    },
    {
      title: "Phụ trách camp",
      dataIndex: "userSupervisorCamp",
      key: "userSupervisorCamp",
      render: (value, { teamSupervisorCamp }) =>
        value?.fullName
          ? `${value.fullName}${teamSupervisorCamp?.teamName ? ` - ${teamSupervisorCamp.teamName}` : ""}`
          : <div className="min-w-[90px]">--</div>
    },
    {
      title: "Người duyệt",
      dataIndex: "userApprove",
      key: "userApprove",
      width: 130,
      align: 'center',
      render: (value) => {
        return <>{value?.fullName ? value?.fullName : "--"}</>;
      },
    },
    {
      title: "Người tạo",
      dataIndex: "userCreate",
      key: "userCreate",
      width: 130,
      align: 'center',
      render: (value) => (value ? value.fullName : "--"),
    },
    {
      title: "Ngày tạo",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 120,
      align: 'center',
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
    {
      title: "Ngày chỉnh sửa",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      width: 120,
      align: 'center',
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
  ];

  const onchangePage = (pageIndex, pageSize) => {
    setSearchParam((prevParams) => ({
      ...prevParams,
      pageIndex: pageIndex,
      pageSize: pageSize,
    }));
  };

  const handlechangeSearch = useCallback(
    (searchFields) => {
      const newParams = { ...searchParams, ...searchFields, pageIndex: 1 };

      setSearchParam(newParams);
    },
    [searchParams]
  );

  return (
    <>
      <section className="flex justify-between mb-4">
        <FormSearch
          onchangeSearch={handlechangeSearch}
          setSearchParam={setSearchParam}
          storageKey="increaseFilter"
        />
      </section>
      <Flex justify="space-between">
        <CountData loading={loading} total={totalPage} text="đề xuất ứng thêm"/>
        <ExportExpense expenseCode="IncreaseAdj" params={searchParams}/>
      </Flex>
      <DataTable
        className="mt-2"
        columns={columns}
        dataSource={dataRequestAdj || []}
        currentPage={searchParams.pageIndex}
        pageSize={searchParams.pageSize}
        totalPage={+totalPage}
        loading={loading}
        scroll={{ x: "max-content", y: 550 }}
        isBorder
        onPageChange={onchangePage}
      />
    </>
  );
};

export default IncreaseRequest;
