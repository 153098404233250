import DataTable from "@/common/DataTable";
import ExpenseLink from "@/common/ExpenseLink";
import { exportAllExpense, exportExpense, getListExpenseApi } from "@/services/base";
import { formatCurrencyVND } from "@/utils/common";
import { Button, Dropdown, Flex, message, Tag, Tooltip } from "antd";
import { DownloadOutlined, DownOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import FormRequest from "@/components/listRequset/FormRequest";
import FormSearch from "@/common/FormSearch";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import { EXPENSE_TYPE_CODES } from "@/utils/const";
import TagStatus from "@/components/listRequset/TagStatus";
import { downloadExcelFile } from "@/utils/utils";
import CountData from "@/common/CountData";

export const initSearchParams = {
  textSearch: "",
  stepId: null,
  statusId: null,
  siteId: null,
  departmentId: null,
  date: [],
  groupId: null,
  pageIndex: 1,
  pageSize: 10,
};

const ListRequest = () => {
  document.title = "Quản lý ngân sách";
  const infoUserState = useRecoilValue(infoUser);
  const [listRequest, setListRequest] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    typeForm: "",
    title: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [exportBtnLoading, setExportBtnLoading] = useState(false);
  const [searchParams, setSearchParam] = useState(initSearchParams);


  const renderStatusCol = (rec) => {
    return <TagStatus className="m-0" record={rec}/>;
  };

  const columns = [
    {
      title: "Mã phiếu",
      dataIndex: "id",
      key: "id",
      width: 85,
      align: 'center',
      fixed: 'left',
      render: (id, record) => (
        <ExpenseLink
          id={id}
          page="NS"
        >{`${record.expenseType.shortName}${id}`}</ExpenseLink>
      ),
    },
    {
      title: <span>Mã BC <Tooltip title="Mã báo cáo của ngày hôm nay"><QuestionCircleOutlined /></Tooltip></span>,
      dataIndex: "expenseReportDailyId",
      key: "expenseReportDailyId",
      align: 'center',
      width: 85,
      render: id => id ? (
        <ExpenseLink id={id} page="BC">{`BC${id}`}</ExpenseLink>
      ) : ("--"),
    },
    {
      title: <span>Trạng thái <Tooltip title="Trạng thái báo cáo của ngày hôm nay"><QuestionCircleOutlined /></Tooltip></span>,
      dataIndex: "reportDailyStatus",
      key: "reportDailyStatus",
      align: 'center',
      width: 120,
    },
    {
      title: "Loại hình",
      dataIndex: "purpose",
      width: 190,
      key: "purpose",
      render: (value, rec) => {
        const isNVL = value?.purposeCode == "NVL" && rec?.numOfPage;

        if (isNVL) {
          return (value?.purposeName ? <>{value?.purposeName} - <span>{rec?.numOfPage} page</span></> : "--")
        }
        
        return (value?.purposeName ? value?.purposeName : "--")
      },
    },
    {
      title: <span>Số tiền <Tooltip title="Số tiền ngân sách"><QuestionCircleOutlined /></Tooltip></span>,
      dataIndex: "totalAmount",
      width: 130,
      key: "totalAmount",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng ngân sách",
      dataIndex: "calculateAmount",
      width: 130,
      key: "calculateAmount",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng chi tiêu",
      dataIndex: "calculateAmountInUse",
      width: 130,
      key: "calculateAmountInUse",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Nhóm HD",
      dataIndex: "group",
      key: "group",
      align: 'center',
      render: (value) => (value ? value.groupName : "--"),
    },
    {
      title: <span>Thời gian <Tooltip title="Thời gian bắt đầu sử dụng ngân sách"><QuestionCircleOutlined /></Tooltip></span>,
      dataIndex: "startDate",
      key: "startDate",
      width: 110,
      align: 'center',
      render: (value) => moment(value + "z").format("DD/MM/YYYY"),
    },
    {
      title: "QUY TRÌNH",
      children: [
        {
          title: "Tên quy trình duyệt",
          dataIndex: "processType",
          key: "processType",
          render: (value) => (value?.name ? value?.name : "--"),
        },
        {
          title: "Bước xử lý",
          dataIndex: "processStep",
          key: "processStep",
          render: (value, rec) => {
            const isProcessingCurrentUser =
              infoUserState?.departmentId == value?.departmentId;
            const stepName = value?.stepName ? value?.stepName : "--";
            const allowStatus = ["Inprogress", "Pending"].includes(rec?.processStatus?.statusCode)
            return isProcessingCurrentUser && allowStatus && value?.stepName ? (
              <Tag color="blue">{stepName}</Tag>
            ) : (
              stepName
            );
          },
        },
      ],
    },
    {
      title: "Trạng thái",
      dataIndex: "processStatus",
      key: "processStatus",
      width: 140,
      align: 'center',
      render: (_, rec) => renderStatusCol(rec),
    },

    {
      title: "Người được giao",
      dataIndex: "userAssignTo",
      key: "userAssignTo",
      align: 'center',
      render: (value) => (value ? value.fullName : <div className="min-w-[100px]">--</div>),
    },
    {
      title: "Phụ trách camp",
      dataIndex: "userSupervisorCamp",
      key: "userSupervisorCamp",
      align: 'center',
      render: (value, { teamSupervisorCamp }) =>
        value?.fullName
          ? <span className="truncate">{value.fullName}{teamSupervisorCamp?.teamName ? ` - ${teamSupervisorCamp.teamName}` : ""}</span>
          : <div className="min-w-[100px]">--</div>
    },
    {
      title: "Người duyệt",
      dataIndex: "userApprove",
      key: "userApprove",
      width: 130,
      align: 'center',
      render: (value) => {
        return <>{value?.fullName ? value?.fullName : "--"}</>;
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 130,
      align: 'center',
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
    {
      title: "Ngày chỉnh sửa",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      width: 120,
      align: 'center',
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
  ];

  useEffect(() => {
    getListExpense(searchParams);
  }, [searchParams]);

  const getListExpense = async (params) => {
    try {
      setLoading(true);
      const res = await getListExpenseApi(params);
      if (res.code === 200 && res.error === 0) {
        setListRequest(res.data);
        setTotalPage(res?.pagination?.total);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setModalState({ isModalOpen: false, title: "", typeForm: "" });
  };

  const handlechangeSearch = useCallback(
    (searchFields) => {
      const newParams = { ...searchParams, ...searchFields };
      setSearchParam({
        ...newParams,
        pageIndex: 1
      });
    },
    [searchParams]
  );

  const onchangePage = (pageIndex, pageSize) => {
    setSearchParam((prevParams) => ({
      ...prevParams,
      pageIndex: pageIndex,
      pageSize: pageSize,
    }));
  };

  const handleExportExpense = async () => {
    setExportBtnLoading(true);
    try {
      const res = await exportExpense("Proposal", searchParams);
      if (res.code === 200 && res.error === 0) {
        downloadExcelFile(res.data, `Exported-expense`);
        message.success("Export file thành công!");
      } else {
        message.error("Export file thất bại!");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setExportBtnLoading(false);
    }
  }

  const handleExportAllExpense = async () => {
    setExportBtnLoading(true);
    try {
      const res = await exportAllExpense(searchParams);
      if (res.code === 200 && res.error === 0) {
        downloadExcelFile(res.data, `Exported-all-expense`);
        message.success("Export file thành công!");
      } else {
        message.error("Export file thất bại!");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setExportBtnLoading(false);
    }
  }

  const ctaItems = [
    {
      key: "1",
      label: (
        <div
          onClick={() =>
            setModalState({
              isModalOpen: true,
              typeForm: EXPENSE_TYPE_CODES.Proposal,
              title: "TẠO NGÂN SÁCH DỰ CHI",
            })
          }
        >
          <PlusOutlined className="mr-2 text-[1rem]" />
          Tạo ngân sách
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={handleExportExpense}>
          <DownloadOutlined className="mr-2 text-[1.2rem]" />
          Export file
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div onClick={handleExportAllExpense}>
          <DownloadOutlined className="mr-2 text-[1.2rem]" />
          Export all file
        </div>
      ),
    },
  ];

  return (
    <>
      <section className="flex justify-between mb-4">
        <FormSearch
          setSearchParam={setSearchParam}
          onchangeSearch={handlechangeSearch}
          isShowStatusReport={true}
          storageKey="expenseFilter"
        />
      </section>
      <Flex justify="space-between">
        <CountData loading={loading} total={totalPage} text="ngân sách"/>
        <Dropdown
          menu={{
            items: ctaItems,
          }}
        >
          <Button
            loading={exportBtnLoading}
            type="primary"
            className="bg-gradient-primary hover:!bg-gradient-secondary active:!bg-gradient-secondary border-l-0"
            onClick={(e) => e.preventDefault()}
          >
            Chức năng
            <DownOutlined />
          </Button>
        </Dropdown>
      </Flex>
      <DataTable
        className="mt-2"
        columns={columns}
        dataSource={listRequest.map((u) => ({ ...u, key: u.id })) || []}
        currentPage={searchParams.pageIndex}
        pageSize={searchParams.pageSize}
        totalPage={+totalPage}
        loading={loading}
        scroll={{ x: "max-content", y: 540 }}
        isBorder
        onPageChange={onchangePage}
      />
      {modalState?.isModalOpen && (
        <FormRequest
          modalState={modalState}
          handleCancel={handleClose}
          loadingForm={loadingForm}
          setLoadingForm={setLoadingForm}
          handleReload={() => getListExpense(searchParams)}
        />
      )}
    </>
  );
};

export default ListRequest;
