import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { getDetailExpenseApi } from "@/services/base";
import { EXPENSE_TYPE_CODES } from "@/utils/const";
import { Button, Divider, Flex, message, Row, Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import DailyReportForm from "@/components/listRequset/DailyReportForm";
import ApproveModalForm from "@/components/expenseDetail/ApproveModalForm";
import { getSteps } from "@/utils/fetchData";
import GroupButtonProcess from "@/components/expenseDetail/GroupButtonProcess";
import ProposeCategoryDetail from "@/common/ProposeCategoryDetail";
import ProgressBar from "@/common/ProgressBar";
import MessageInfo from "@/common/MessageInfo";
import TicketInfo from "@/common/TicketInfo";
import ApproveHistoryInfo from "@/common/ApproveHistoryInfo";

const CloseRequestDetail = () => {
  const nav = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [closeRequestTicket, setCloseRequestTicket] = useState();
  const navigate = useNavigate();
  const [steps, setSteps] = useState([]);
  const [approveModal, setApproveModal] = useState({
    isOpen: false,
  });
  const [dailyModal, setDailyModal] = useState({
    isOpen: false,
    isConfirm: false,
    title: "",
    isSetMessageValue: false,
  });

  const fetchCloseRequestTicket = async (id) => {
    setLoading(true);
    try {
      const res = await getDetailExpenseApi(id, EXPENSE_TYPE_CODES.RequestClose);
      if (
        (res.code === 400 && res.error === -4) ||
        (res.code === 404 && res.error === -5)
      )
        return nav("/404");
      if (res.code !== 200 && res.error !== 0) return message.error(res?.error);
      setCloseRequestTicket(res.data);
      await getSteps(res?.data?.processTypeId, setSteps);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCloseRequestTicket(id);
  }, []);

  useEffect(() => {
    fetchCloseRequestTicket(id);
  }, []);

  return (
    <>
      {loading ? (
        <div className="w-full min-h-[360px] flex items-center justify-center ">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <ProgressBar
            dataDetail={closeRequestTicket}
            steps={steps}
            title="Phiếu yêu cầu kết toán"
            reload={() => fetchCloseRequestTicket(id)}
          />
          <Divider />
          <TicketInfo
            dataDetail={closeRequestTicket}
            handleReload={() => fetchCloseRequestTicket(id)}
          />
          <MessageInfo messInfor={closeRequestTicket?.messInfor} />
          <ProposeCategoryDetail dataDetail={closeRequestTicket} />
          <ApproveHistoryInfo
            approvalHistory={closeRequestTicket?.approvalHistory}
          />
          <Row className="mt-10">
            <Flex justify="space-between" className="w-full">
              <Button
                type="primary"
                className="px-4"
                onClick={() => navigate(-1)}
              >
                <ArrowLeftOutlined />
                Trở về
              </Button>
              <GroupButtonProcess
                dataDetail={closeRequestTicket}
                id={id}
                loading={loading}
                setApproveModal={setApproveModal}
                getExpenseDetail={fetchCloseRequestTicket}
                setLoading={setLoading}
              />
            </Flex>
          </Row>
          {dailyModal.isOpen && (
            <DailyReportForm
              modalState={dailyModal}
              handleCancel={() => setDailyModal({ isOpen: false })}
              dataDetail={closeRequestTicket}
              handleReload={() => fetchCloseRequestTicket(id)}
            />
          )}
          {approveModal.isOpen && (
            <ApproveModalForm
              approveModal={approveModal}
              handleCancel={() => setApproveModal({ isOpen: false })}
              dataDetail={closeRequestTicket}
              handleReload={() => fetchCloseRequestTicket(id)}
            />
          )}
        </>
      )}
    </>
  );
};

export default CloseRequestDetail;
