import { Button, Checkbox, Col, Divider, Flex, Form, Modal, Row, Select } from "antd";
import React, { memo, useEffect, useState } from "react";
import dayjs from "dayjs";
import { getListGroups } from "@/utils/fetchData";
import { handleClearLoadMore, handleLoadMore, handleSearchLoadMore } from "@/utils/utils";
import { IoMdOptions } from "react-icons/io";
import PurposesForm from "@/components/form/filter/PurposesForm";
import SearchForm from "@/components/form/filter/SearchForm";
import ProcessStatusForm from "@/components/form/filter/ProcessStatusForm";
import DailyReportStatusForm from "@/components/form/filter/DailyReportStatusForm";
import SiteForm from "@/components/form/filter/SiteForm";
import RangePickerForm from "@/components/form/filter/RangePickerForm";
import DepartProcessForm from "@/components/form/filter/DepartProcessForm";

const initParams = {
  textSearch: "",
  teamSupervisorCamp: "",
  processTypeName: "",
  processStepName: "",
  processDepartmentId: null,
  reportDailyStatus: "",
  numberAccount: "",
  purposeId: null,
  pageIndex: 1,
  pageSize: 20,
  statuses: [],
  siteId: null,
  teamId: null,
  groupId: null,
  fromDate: null,
  toDate: null,
};

const FormSearch = ({
  setSearchParam,
  onchangeSearch,
  isShowStatusReport,
  storageKey
}) => {
  const [formSearch] = Form.useForm();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [listGroups, setListGroups] = useState([]);
  const [groupParams, setGroupParams] = useState(initParams);

  const defaultFilters = ["statuses", "siteId", "groupId", "date", "reportDailyStatus", "purposeId",
    "teamSupervisorCamp", "processTypeName", "processStepName", "processDepartmentId",];

  const filterOptions = [
    { label: "Trạng thái", value: "statuses", sortValue: 1 },
    { label: "Số tài khoản", value: "numberAccount", sortValue: 2 },
    { label: "Team phụ trách báo camp", value: "teamSupervisorCamp", sortValue: 2 },
    { label: "Tên quy trình duyệt", value: "processTypeName", sortValue: 2 },
    { label: "Tên bước xử lý", value: "processStepName", sortValue: 2 },
    { label: "Bộ phận xử lý", value: "processDepartmentId", sortValue: 2 },
    { label: "Loại hình quảng cáo", value: "purposeId", sortValue: 3 },
    { label: "Trang", value: "siteId", sortValue: 5 },
    { label: "Nhóm hậu đài", value: "groupId", sortValue: 6 },
    { label: "Ngày", value: "date", sortValue: 7 },
  ];

  if (isShowStatusReport) {
    filterOptions.push({ label: "Trạng thái báo cáo", value: "reportDailyStatus", sortValue: 4 });
  }

  useEffect(() => {
    const storedFilters = localStorage.getItem(storageKey);
    if (storedFilters) {
      setSelectedFilters(JSON.parse(storedFilters));
    } else {
      setSelectedFilters(defaultFilters);
      localStorage.setItem(storageKey, JSON.stringify(defaultFilters));
    }
  }, [storageKey]);

  const handleOpenFilterModal = () => setIsModalVisible(true);
  const handleCloseFilterModal = () => setIsModalVisible(false);

  const handleFilterSelection = (checkedValues) => {
    setSelectedFilters(checkedValues);
    localStorage.setItem(storageKey, JSON.stringify(checkedValues));
  };

  useEffect(() => {
    if (selectedFilters.includes("groupId")) {
      getListGroups(groupParams, setListGroups, setGroupParams);
    }
  }, [
    selectedFilters,
    groupParams.pageSize,
    groupParams.textSearch,
    groupParams.siteId,
  ]);

  const handleReset = () => {
    formSearch.resetFields();
    setSearchParam(initParams);
    onchangeSearch(initParams);
  };

  const handleSiteChange = (value) => {
    formSearch.setFieldsValue({ teamId: null, groupId: null });
    setGroupParams((prev) => ({
      ...prev,
      siteId: value,
    }));
  };

  function onSearch(data) {
    const date = data?.date || [];
    const hasDate = date.length > 0;
    const formatDate = (date, endOfDay = false) =>
      date ? dayjs(date)[endOfDay ? 'endOf' : 'startOf']('day').format("YYYY-MM-DDTHH:mm:ss") : null;
    const searchParams = {
      ...data,
      fromDate: hasDate ? formatDate(date[0]) : null,
      toDate: hasDate ? formatDate(date[1], true) : null,
      statuses: data.statuses ? data.statuses.join(',') : '',
    };

    onchangeSearch(searchParams);
  }

  return (
    <div className="w-full">
      <Form
        onReset={handleReset}
        form={formSearch}
        layout={"inline"}
        onFinish={onSearch}
      >
        <Flex className="w-full" justify="space-between" gap={8}>
          <Row gutter={[8, 8]}>
            <Col>
              <SearchForm name="textSearch" isSearchIcon={true} />
            </Col>
            {selectedFilters.includes("numberAccount") && (
              <Col>
                <SearchForm name="numberAccount" overridePlaceholder="Số tài khoản..." />
              </Col>
            )}
            {selectedFilters.includes("teamSupervisorCamp") && (
              <Col>
                <SearchForm name="teamSupervisorCamp" overridePlaceholder="Team phụ trách camp..." />
              </Col>
            )}
            {selectedFilters.includes("processTypeName") && (
              <Col>
                <SearchForm name="processTypeName" overridePlaceholder="Tên quy trình duyệt..." />
              </Col>
            )}
            {selectedFilters.includes("processStepName") && (
              <Col>
                <SearchForm name="processStepName" overridePlaceholder="Tên bước xử lý..." />
              </Col>
            )}
            {selectedFilters.includes("processDepartmentId") && (
              <Col>
                <DepartProcessForm />
              </Col>
            )}
            {selectedFilters.includes("statuses") && (
              <Col>
                <ProcessStatusForm />
              </Col>
            )}
            {selectedFilters.includes("purposeId") && (
              <Col>
                <PurposesForm />
              </Col>
            )}
            {selectedFilters.includes("reportDailyStatus") && isShowStatusReport && (
              <Col>
                <DailyReportStatusForm />
              </Col>
            )}
            {selectedFilters.includes("siteId") && (
              <Col>
                <SiteForm handleSiteChange={handleSiteChange} />
              </Col>
            )}
            {selectedFilters.includes("groupId") && (
              <Col>
                <Form.Item name="groupId" className="!m-0">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Nhóm hậu đài..."
                    onSearch={(e) => handleSearchLoadMore(e, setGroupParams)}
                    onClear={handleClearLoadMore(setGroupParams)}
                    filterOption={(input, option) =>
                      (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    onPopupScroll={handleLoadMore(groupParams, setGroupParams)}
                    className="!w-[200px]"
                  >
                    {listGroups?.map((group) => (
                      <Select.Option key={group.id} value={group.id}>
                        {group?.groupName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {selectedFilters.includes("date") && (
              <Col>
                <RangePickerForm />
              </Col>
            )}
          </Row>
          <Flex gap={8}>
            <Button className="w-16 bg-primary hover:opacity-70" type="primary" htmlType="submit">
              Lọc
            </Button>
            <Button className="w-16" htmlType="reset">
              Xóa
            </Button>
            <Button title="Chọn bộ lọc" className="px-2" onClick={handleOpenFilterModal} type="text">
              <IoMdOptions className="text-[18px] text-primary" />
            </Button>
          </Flex>
        </Flex>
      </Form>

      <Modal
        title="Chọn bộ lọc"
        open={isModalVisible}
        footer={null}
        onCancel={handleCloseFilterModal}
      >
        <Divider className="mt-3 mb-2" />
        <Checkbox.Group
          options={filterOptions.sort((a, b) => a.sortValue - b.sortValue)}
          value={selectedFilters}
          onChange={handleFilterSelection}
          className="grid grid-cols-2 gap-3 mt-4"
        />
      </Modal>
    </div>
  );
};

export default memo(FormSearch);
