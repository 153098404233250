import React from "react";
import { Modal, Divider, Row, Col, Typography, Empty, Button, Tag } from "antd";
import ExpenseLink from "@/common/ExpenseLink";
import moment from "moment/moment";
import { useNavigate } from "react-router";
import { goToPage } from "@/utils/utils";
import { formatCurrencyVND } from "@/utils/common";

const HistorySpending = ({ isModalOpen, handleCancel, dataDetail }) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        title="DANH SÁCH LỊCH SỬ CHI TIÊU"
        open={isModalOpen}
        width="1600px"
        onCancel={handleCancel}
        footer={null}
        className="custom-modal-history"
      >
        <div className="mb-4">
          {dataDetail && dataDetail.dailyReportHistory.length > 0 ? (
            dataDetail.dailyReportHistory.map((item, index) => (
              <>
                <Row key={index}>
                  <Col span={2}>
                    <Typography.Text strong>Mã BC: </Typography.Text>
                    <ExpenseLink id={item.expenseId} page="BC">BC{item?.expenseId ?? "--"}</ExpenseLink>
                  </Col>
                  <Col span={4}>
                    <Typography.Text strong>Người báo cáo: </Typography.Text>
                    <Typography.Text>
                      {item?.userCreate?.fullName ?? "--"}
                    </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Typography.Text strong>Tiền camp chi tiêu: </Typography.Text>
                    <Typography.Text>
                      {formatCurrencyVND(item?.amountCamp) ?? "--"}
                    </Typography.Text>
                  </Col>
                  <Col span={3}>
                    <Typography.Text strong>Dôi cam: </Typography.Text>
                    <Typography.Text>
                      {formatCurrencyVND(item?.amountAdditional) ?? "--"}
                    </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Typography.Text strong>Tổng tiền: </Typography.Text>
                    <Typography.Text>
                      {item?.amount === 0 ? '--' : formatCurrencyVND(item?.amount)}
                    </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Typography.Text strong>Thời gian: </Typography.Text>
                    <Typography.Text>
                      {moment(item?.creationDate + "z").format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </Typography.Text>
                  </Col>
                  <Col span={3}>
                    <Typography.Text strong>Trạng thái: </Typography.Text>
                    {item?.processStatus?.statusName === "Từ chối" ? (
                      <Tag color="red">{item?.processStatus?.statusName}</Tag>
                    ) : item?.processStatus?.statusName === "Đã phê duyệt" || item?.processStatus?.statusName === "Hoàn thành" ? (
                      <Tag color="green">{item?.processStatus?.statusName}</Tag>
                    ) : item?.processStatus?.statusName === "Đang xử lý" ? (
                      <Tag color="blue">{item?.processStatus?.statusName}</Tag>
                    ) : (
                      <Tag color="orange">
                        {item?.processStatus?.statusName}
                      </Tag>
                    )}
                  </Col>
                </Row>
                <Divider/>
              </>
            ))
          ) : (
            <Empty/>
          )}
        </div>
      </Modal>
    </>
  );
};

export default HistorySpending;
